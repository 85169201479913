<template>
  <v-dialog
      v-model="permissionPopup"
      content-class="filterDialog"
      hide-overlay
      width="400px"
  >
    <v-card
        style="
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: visible;
        "
    >
      <div class="dialog-large-circle">
        <div
            :style="{
              backgroundColor: this.dialogPermissionPopupContent.color,
            }"
            class="dialog-circle"
        >
          <span>i</span>
        </div>
      </div>

      <h3
          :style="{
            color: this.dialogPermissionPopupContent.color,
            marginTop: '60px',
          }"
          class="permision-label"
      >
        {{ this.dialogPermissionPopupContent.title }}
      </h3>
      <div class="mt-4"></div>
      <!-- Three rows of content -->
      <div class="mt-4"></div>
      <h3 class="filter-label">
        {{ this.dialogPermissionPopupContent.confirmText }}
      </h3>
      <div class="mt-4"></div>
      <div class="d-flex justify-center">
        <v-btn
            v-if="allViewed"
            :style="{
              color: dialogPermissionPopupContent.btnColor,
              backgroundColor: dialogPermissionPopupContent.color,
            }"
            class="filter-remove-btn mx-3"
            variant="text"
            @click="
              sendReviewMockup(
                taskStore.selectedTask.id,
                this.dialogPermissionPopupContent.actionType
              )
            "
        >
          <v-icon> mdi-send-outline</v-icon>
          Envoyer
        </v-btn>
        <v-btn
            class="filter-remove-btn"
            variant="text"
            @click="dialogClose()"
        >
          <v-icon> mdi-close</v-icon>
          Annuler
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import {useTaskStore} from "@/store/taskStore.js";
import {useRevisionStore} from "@/store/useRevisionStore.js";
import {useDialogStateStore} from "@/store/DialogStateStore.js";
import {fileService as fileApi, STATUS_APPROVED, STATUS_TO_CORRECT} from "@/services/file.service.js"
import {ADD_COMMENT_MSG, commentService, VALIDATE_REVISION_MSG} from "@/services/comment.service.js";

const fileService = fileApi()
export default {
  data() {
    return {
      dialogPermissionPopupContent: {
        actionType: '',
        title: '',
        color: '',
        confirmText: '',
        btnColor: '',
      },
    }
  },
  props: {
    commentSubmitPermissionPopup: {type: Boolean, required: true},
    actionTypePermission: {type: String, required: true},
    authrizeCloseDialog: {type: Boolean, required: false}
  },
  computed: {
    permissionPopup() {
      return this.commentSubmitPermissionPopup;
    },
    allRevisionIds() {
      return this.revisionStore.getRevisionsList().lastRevisionIds
    },
    add_comment_msg() {
      return ADD_COMMENT_MSG;
    },
    revision_validate_msg() {
      return VALIDATE_REVISION_MSG;
    },
    status_approved() {
      return STATUS_APPROVED;
    },
    status_to_correct() {
      return STATUS_TO_CORRECT;
    },
    allViewed() {
      return this.dialogStateStore.allFormatsViewed;
    },
    enableSendCommentButton(){
      return this.revisionStore.getRevisionsList().total_comments > 0;
    },

  },
  setup() {
    const taskStore = useTaskStore()
    const revisionStore = useRevisionStore()
    const dialogStateStore = useDialogStateStore()
    return {
      taskStore,
      revisionStore,
      dialogStateStore
    }
  },
  mounted() {
    this.setDialogPermissionPopupContent(this.allViewed, this.enableSendCommentButton);
  },
  watch: {
    // Add watch for allViewed
    allViewed: {
      handler(newValue) {
        this.setDialogPermissionPopupContent(newValue, this.enableSendCommentButton);
      },
      immediate: true
    }
  },
  methods: {

    setDialogPermissionPopupContent(allView, enableSendCommentButton) {
      if (allView && enableSendCommentButton) {
        this.dialogPermissionPopupContent = {
          actionType: 'sendComments',
          title: 'Êtes-vous sûr de vouloir envoyer vos commentaires ?',
          color: '#C90D0F !important',
          confirmText: 'Une fois vos demandes envoyées, vous ne pourrez plus faire de nouvelle demande de correction avant la prochaine itération.',
          btnColor: '#FFF',
        };
      } else if (allView && !enableSendCommentButton) {
        this.dialogPermissionPopupContent = {
          actionType: 'validateMockups',
          title: 'Êtes-vous sûr de vouloir valider les maquettes ?',
          color: '#0F8000 !important',
          confirmText: 'Une fois les maquettes validées, vous ne pourrez plus faire de nouvelles demandes de correction.',
          btnColor: '#FFF',
        };
      } else { // Not all viewed
        this.dialogPermissionPopupContent = {
          actionType: 'NotAuthExit',
          title: 'Veuillez consulter toutes les révisions en cours.',
          color: '#C90D0F !important',
          confirmText: 'Il y a d’autres révisions à consulter.',
          btnColor: '#FFF',
        };
      }
    },
    /**
     * Validates mockups for the specified task ID.
     * @param {Number} taskId - The ID of the task.
     * @param commentModelId
     * @async
     */
    async validateMockups(taskId, commentModelId) {
      for (const revId of this.allRevisionIds) {
        await fileService.updateRevisionStatus(revId, this.status_approved);
      }
      await commentService().addSyncCommentGrc(taskId, commentModelId);
      this.$emit('permissionDialogClose', false);
      this.$emit('modelLargeViewClose', false);
    },


    /**
     * Sends review mockups based on the tasks and action type.
     * @param task
     * @param {String} actionType - The type of action (sendComments or validateMockups).
     * @async
     */
    async sendReviewMockup(task, actionType) {
      if (task === undefined || task === null) {
        console.error(' task not defined ');
        return;
      }
      if (actionType === 'sendComments') {
        await this.sendComments(task, this.add_comment_msg);
      } else {
        await this.validateMockups(task, this.revision_validate_msg);
      }
    },

    /**
     * Sends comments for a specific task.
     * @param {Number} taskId - The ID of the task.
     * @param commentModelId
     * @async
     */
    async sendComments(taskId, commentModelId) {
      try {

        for (const revId of this.allRevisionIds) {
          await fileService.updateRevisionStatus(revId, this.status_to_correct);
        }
        await commentService().addSyncCommentGrc(taskId, commentModelId);
        this.$emit('permissionDialogClose', false);
        this.$emit('modelLargeViewClose', false);
      } catch (error) {
        console.error('Error sending comments:', error);
      }
    },

    dialogClose() {
      //this.commentSubmitPermissionPopup = false
      this.$emit('PermissionDialogClose', false);
    }
  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.filterDialog {
  max-width: 300px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 82px;
}

.permision-label {
  text-align: center;
  font: normal normal lighter 24px/19px Roboto;
  color: #494949;
  opacity: 1;
}

.permision-label-content {
  text-align: center;
  font: normal normal lighter 16px/18px Roboto;
  color: #494949;
  opacity: 1;
}

.filter-remove-btn {
  background-color: transparent !important;
  color: #30322f;
  border: none;
  border: 1px solid #30322f;
  padding: 5px 10px;
  cursor: pointer;
}

.dialog-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mono';
}

.dialog-circle span {
  font-size: 40px;
  font-weight: lighter;
  color: #fff;
}

.dialog-large-circle {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
}
</style>