<template>
  <v-row>
    <v-col cols="12" style="background: #30322f">
      <v-col :cols="isCommentPopupOpen ? 8 : 12" class="mockup-view" :style="{ maxWidth: isCommentPopupOpen ? '70%' : '' }">
        <div id="element-to-convert" :key="selectedFile.id" class="image-container" :style="selectedFile.view === 'mobile' ? { width: '376px' } : {}" style="overflow: auto; cursor: grab">
          <v-responsive style="overflow: auto; cursor: default" :style="selectedFile.view === 'mobile' ? { height: '670px !important' } : {}">
            <img ref="image" id="image-to-download" :src="dialogInfoStore.getImageSource(selectedFile.preview)" :alt="selectedFile.preview" :class="imagePined ? 'image-container-with-filter' : ''" :style="{ transform: `scale(${zoomLevel}) translate(${transformX}%, ${transformY}px)`, transition: 'transform 0.1s ease' }" class="image-container-responsive" @load="onImageLoad" @mousedown="handleMouseDown" @mouseup="handleMouseUp" @mouseleave="stopDragging" @click="setNewCommentPosition" />
            <template v-if="isCommentPopupOpen && zoomLevel === 1">
              <div v-for="(comment, index) in comments" :key="index" :style="{ top: `${(comment.pins.CoordinateX / 100) * imageHeight}px`, left: `${(comment.pins.CoordinateY / 100) * imageWidth}px`, backgroundColor: comment.status.color }" class="badge" :id="`pin-${comment.id}`" @click="showComment(index)">
                {{ this.user.is_client ? comment.commentNbrClient : comment.commentNbrAdmin }}
              </div>
              <div v-if="imagePined && (commentPin.CoordinateX !== 0 || commentPin.CoordinateY !== 0)" :style="{ top: `${(commentPin.CoordinateX / 100) * imageHeight}px`, left: `${(commentPin.CoordinateY / 100) * imageWidth}px` }" class="pin-icon">
                <v-icon :color="'#05A9F4'">mdi-pin-outline</v-icon>
              </div>
            </template>
          </v-responsive>
        </div>
        <DialogDetailFooter ref="dialogDetailFooter" :init-zoom-level="initZoomLevel" :zoom-adjustments="{ zoomLevel, transformX, transformY }" @update-zoom="handleUpdateZoomLevel" @new-file="handleFileChange" @model-large-view-close="handleModelLargeViewClose" />
      </v-col>
      <v-col v-if="isCommentPopupOpen" cols="4">
        <DialogDetailComment :selected-rev="selectedRev" :comment-pin="commentPin" :highlighted-comments="highlightedComments" :image-pined="imagePined" :init-zoom-level="initZoomLevel" @image-pined="handleImagePined" @reload-data="handleFileChange" @reset-comment-form="handleResetCommentForm" @after-comment-save="handleAfterCommentSave" />
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { useRevisionStore } from '@/store/useRevisionStore.js';
import translate from '@/utils/translate.js';
import 'vuetify/styles';
import DialogDetailFooter from "@/components/AtomicArchitect/Molecules/DialogDetailFooter.vue";
import DialogDetailComment from "@/components/AtomicArchitect/Molecules/DialogDetailComment.vue";
import { useDialogInfoStore } from "@/store/DialogInfoStore.js";
import { useUserStore } from "@/store/userStore.js";

export default {
  components: {
    DialogDetailFooter,
    DialogDetailComment
  },
  props: {
    isCommentPopupOpen: { type: Boolean, required: true },
    currentIndex: { type: Number, required: false }
  },
  data() {
    return {
      comments: [],
      imageHeight: 0,
      imageWidth: 0,
      commentPin: { CoordinateX: null, CoordinateY: null },
      imagePined: false,
      zoomLevel: 1.0,
      transformX: 0,
      transformY: 0,
      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
      container: null,
      clickX: 0,
      clickY: 0,
      clickTimeout: null,
      isLongClick: false,
      highlightedComments: {},
      highlightedCommentId: null,
    };
  },
  mounted() {
    this.initZoomLevel();
    this.updateImageDimensions();
    window.addEventListener('resize', this.updateImageDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageDimensions);
  },
  setup() {
    const revisionStore = useRevisionStore();
    const dialogInfoStore = useDialogInfoStore();
    const userStore = useUserStore();
    const user = userStore.user;
    return { revisionStore, dialogInfoStore, user };
  },
  computed: {
    translate() {
      return translate;
    },
    selectedFile() {
      return this.selectedRev.file;
    },
    selectedRev() {
      return this.revisionStore.getSelectedRev();
    }
  },
  watch: {
    zoomLevel(newZoomLevel) {
      this.selectedZoomLevel = (newZoomLevel * 100).toFixed(0).toString();
    },
    selectedRev(newRev) {
      if (newRev) {
        this.initZoomLevel();
        this.updateImageDimensions();
        this.comments = newRev.comments;
      }
    },
    isCommentPopupOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.updateImageDimensions();
          window.addEventListener('resize', this.updateImageDimensions);
        });
      } else {
        window.removeEventListener('resize', this.updateImageDimensions);
      }
    },
  },
  methods: {
    updateImageDimensions() {
      const img = this.$refs.image;
      if (img) {
        this.imageHeight = img.clientHeight;
        this.imageWidth = img.clientWidth;
      }
    },
    onImageLoad() {
      this.$nextTick(() => {
        this.updateImageDimensions();
        this.comments = this.selectedRev.comments;
      });
    },
    startDragging(event) {
      this.container = document.getElementById('element-to-convert');
      this.isDragging = true;
      this.startX = event.pageX - this.container.offsetLeft;
      this.startY = event.pageY - this.container.offsetTop;
      this.scrollLeft = this.container.scrollLeft;
      this.scrollTop = this.container.scrollTop;
      document.addEventListener('mousemove', this.onDragging);
      document.addEventListener('mouseup', this.stopDragging);
    },
    onDragging(event) {
      this.container = document.getElementById('element-to-convert');
      if (!this.isDragging) return;
      event.preventDefault();
      const x = event.pageX - this.startX;
      const y = event.pageY - this.startY;
      this.container.scrollLeft = this.scrollLeft - x;
      this.container.scrollTop = this.scrollTop - y;
    },
    stopDragging() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDragging);
      document.removeEventListener('mouseup', this.stopDragging);
      clearTimeout(this.clickTimeout);
    },
    setClickPosition(event) {
      this.container = document.getElementById('element-to-convert');
      if (!this.container) {
        console.error('Container ref is not defined');
        return;
      }
      this.clickX = event.clientX - this.container.offsetLeft + this.container.scrollLeft;
      this.clickY = event.clientY - this.container.offsetTop + this.container.scrollTop;
    },
    handleMouseDown(event) {
      this.setClickPosition(event);
      this.clickTimeout = setTimeout(() => {
        this.isLongClick = true;
        this.startDragging(event);
      }, 500);
    },
    handleMouseUp(event) {
      clearTimeout(this.clickTimeout);
      if (this.isLongClick) {
        this.stopDragging();
        this.isLongClick = false;
      } else {
        this.setClickPosition(event);
      }
    },
    setNewCommentPosition(event) {
      if (this.imagePined) {
        const rect = this.$refs.image.getBoundingClientRect();
        const getCoordinatePercentage = (position, offset, dimension) => ((position - offset) / dimension) * 100;
        const coordX = getCoordinatePercentage(event.clientY, rect.top, rect.height);
        const coordY = getCoordinatePercentage(event.clientX, rect.left, rect.width);
        if (coordX >= 0 && coordX <= 100 && coordY >= 0 && coordY <= 100) {
          this.commentPin = { CoordinateX: coordX, CoordinateY: coordY };
        }
      }
    },
    showComment(index) {
      const clickedComment = this.comments[index] ?? null;
      if (clickedComment == null) {
        return;
      }
      if (this.highlightedCommentId && this.highlightedCommentId !== clickedComment.id) {
        const previousCommentElement = document.getElementById(`comment-${this.highlightedCommentId}`);
        if (previousCommentElement) {
          previousCommentElement.style.background = "";
        }
      }
      this.highlightedCommentId = clickedComment.id;
      this.$nextTick(() => {
        const commentElement = document.getElementById(`comment-${clickedComment.id}`);
        if (commentElement) {
          commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          commentElement.style.background = "rgba(136, 130, 130, 0.48)";
          setTimeout(() => {
            if (this.highlightedCommentId === clickedComment.id) {
              commentElement.style.background = "";
            }
          }, 1000);
        } else {
          console.warn('Comment element not found for ID:', clickedComment.id);
        }
      });
    },
    handleUpdateZoomLevel({ zoomLevel, transformX, transformY }) {
      this.zoomLevel = zoomLevel;
      this.transformX = transformX;
      this.transformY = transformY;
    },
    initZoomLevel() {
      this.zoomLevel = 1.0;
      this.transformX = 0;
      this.transformY = 0;
    },
    handleFileChange(newFile) {
      if (newFile) {
        this.revisionStore.getRevisionsByFileId(newFile.id);
        this.updateImageDimensions();
        this.comments = this.revisionStore.getSelectedRev().comments;
      }
    },
    handleModelLargeViewClose(newValue) {
      this.$emit('modelLargeViewClose', newValue);
    },
    handleImagePined(isImagePined) {
      this.imagePined = isImagePined;
    },
    handleResetCommentForm(resentCommentForm) {
      this.handleImagePined(resentCommentForm.imagePined);
      this.commentPin = resentCommentForm.commentPin;
    },
    handleAfterCommentSave(newComments) {
      this.comments = newComments;
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.select-wrapper {
  position: relative;
  margin-left: 5px;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    outline: white;

    &::-ms-expand {
      display: none;
    }
  }

  &.open {
    select {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><polygon points="0,0 5,5 10,0" fill="%23fff" class="arrow"/></svg>');
    }
  }

  select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><polygon points="0,5 5,0 10,5" fill="%23fff" class="arrow"/></svg>');
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  option[data-icon='mdi-cellphone']:before {
    background-position: 0px 0px;
  }

  option[data-icon='mdi-tablet']:before {
    background-position: -20px 0px;
  }

  option[data-icon='mdi-monitor']:before {
    background-position: -40px 0px;
  }
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  margin: 80px 10px 20px 5px;
  scrollbar-width: none;
  overflow-x: auto;
}

.image-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  cursor: pointer;
  border: 4px solid #ffffff;
  box-shadow: 0px 0px 16px #000000cc;
  font: normal normal bold 13px/18px Roboto, sans-serif;
  width: 28px;
  height: 28px;
}

.pin-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px dashed #05a9f4;
  border-radius: 60%;
}

.date {
  text-align: left;
  font: normal normal normal 13px/18px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #494949;
  opacity: 1;
}

.image-container-responsive {
  display: flex;
  max-width: 100%;
  position: relative;
  scrollbar-width: none;
  overflow-x: auto;
  object-fit: cover;
}

@media (max-width: 1174px) {
  .image-container-responsive {
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    max-width: 100%;
    position: relative;
    scrollbar-width: none;
    overflow-x: auto;
  }
}

.image-container-with-filter {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAHlBMVEUAAAADqvUGqvYGqfMGqfQFqfQFqfQFqfQFqfT///9g5dsIAAAACHRSTlMAS1GAiMz4+raKmaUAAAABYktHRAnx2aXsAAAANUlEQVQY02NgwAc4OoCgARuHoQOMaM5hbmdgqDCAciyKGBjEWmCqwAAbh4GBNQDJExRzsAEAW5MYpcwhmJ4AAAAASUVORK5CYII='),
  auto;
  filter: sepia(100%) hue-rotate(45deg);
  border: 2px solid yellow;
}

.image-wrapper img {
  width: 100%;
  height: auto;
}

.mockup-view {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: monospace;
}
</style>