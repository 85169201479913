<template>
  <v-container>
    <v-form @submit.prevent="execCmd">
      <v-select
          :items="items"
          label="Select an option"
          v-model="selectedItem"
          item-title="name"
          item-value="id"
          class="custom-selects"
          width="100"
      ></v-select>
      <v-btn :disabled="loading" type="submit" color="primary" style="float: right;">Executer</v-btn>
    </v-form>
    <div v-if="loading" class="loading-indicator">
      Executing command...
    </div>
    <div v-if="message" :class="{'error-message': !success, 'success-message': success}" class="message">
      {{ message }}
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      items: [
        {id: 1, name: 'Syncroniser les utilisateurs'},
        {id: 2, name: 'Syncronisez les projects, les tâches avec grc'},
        {id: 3, name: 'Syncronisez les fichiers'},
        {id: 4, name: 'Vérifier si la révisions sont terminées sinon les terminer'}
      ],
      selectedItem: null,
      message: '',
      success: false,
      loading: false
    };
  },
  methods: {
    async execCmd() {
      this.loading = true;
      this.message = ''; // Clear any previous messages
      try {
        const response = await axios.post(
            "sync_manual",
            { option: this.selectedItem }
        );
        if (response.data.success) {
          this.success = true;
          this.message = 'Operation successful!';
        } else {
          this.success = false;
          this.message = response.data.error || 'Operation failed!';
        }
      } catch (error) {
        this.success = false;
        this.message = error.response ? error.response.data.error : 'Sync failed!';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.custom-selects {
  width: 500px;
  flex: auto;
}

.loading-indicator {
  margin-top: 20px;
  color: blue;
}

.message {
  margin-top: 20px;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}
</style>
