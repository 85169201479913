<template>
  <div class="history-container">
    <h2>Historique des commentaires étiqueté Spécification: </h2>
    <div v-for="(project, projectName) in history" :key="projectName" class="project-section">
      <div class="project-header" @click="toggleSection('project', projectName)">
        <h2>{{ projectName }}</h2>
        <span class="toggle-icon">{{ isSectionExpanded('project', projectName) ? '▼' : '▶' }}</span>
      </div>
      <div v-if="isSectionExpanded('project', projectName)" class="project-content">
        <h4>Liste des taches </h4>
        <div v-for="(task, taskId) in project" :key="taskId" class="task-section">
          <div class="task-header" @click="toggleSection('task', projectName, taskId)">
            <h3>Id tâche GRC: {{ taskId }}</h3>
            <span class="toggle-icon">{{ isSectionExpanded('task', projectName, taskId) ? '▼' : '▶' }}</span>
          </div>
          <div v-if="isSectionExpanded('task', projectName, taskId)" class="task-content">
            <h4>Format des fichiers </h4>
            <div v-for="(file, fileName) in task" :key="fileName">
              <div v-for="(device, deviceName) in file" :key="deviceName" class="device-section">
                <div class="device-header" @click="toggleSection('device', projectName, taskId, deviceName)">
                  <h4>{{ translate.translateFormat(deviceName) }}</h4>
                  <span class="toggle-icon">{{ isSectionExpanded('device', projectName, taskId, deviceName) ? '▼' : '▶' }}</span>
                </div>
                <div v-if="isSectionExpanded('device', projectName, taskId, deviceName)" class="device-content">
                  <h4>Liste des révisions</h4>
                  <div v-for="(revision, revisionName) in device" :key="revisionName" class="revision-section">
                    <div class="revision-header">
                      <h4>{{ revisionName }}</h4>
                    </div>
                    <table class="change-table">
                      <thead>
                      <tr>
                        <th>Utilisateur</th>
                        <th>ID Commentaire</th>
                        <th>Contenu</th>
                        <th>Du</th>
                        <th>A</th>
                        <th>Date</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="change in revision" :key="change.id" class="change-item">
                        <td>{{ change.userEmail }}</td>
                        <td>{{ change.comment.id }}</td>
                        <td>{{ change.comment.content }}</td>
                        <td>{{ change.oldValue ? 'Oui' : 'Non' }}</td>
                        <td>{{ change.newValue ? 'Oui' : 'Non' }}</td>
                        <td>{{ formatDate(change.createdAt) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { reactive } from 'vue';
import translate from "@/utils/translate.js";

export default {
  data() {
    return {
      history: {},
      expandedSections: reactive({}),
    };
  },
  mounted() {
    this.fetchHistory();
  },
  methods: {
    toggleSection(type, projectName, taskId = null, deviceName = null) {
      const key = deviceName ? `${projectName}-${taskId}-${deviceName}` : taskId ? `${projectName}-${taskId}` : projectName;
      this.expandedSections[key] = !this.expandedSections[key];
    },
    isSectionExpanded(type, projectName, taskId = null, deviceName = null) {
      const key = deviceName ? `${projectName}-${taskId}-${deviceName}` : taskId ? `${projectName}-${taskId}` : projectName;
      return this.expandedSections[key] || false;
    },
    async fetchHistory() {
      try {
        const response = await axios.get('history/Comment/taggedSpec');
        this.history = response.data.history;
      } catch (error) {
        console.error('Error fetching history:', error);
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
  },
  computed: {
    translate() {
      return translate;
    },
  },
};
</script>

<style scoped>
/* General Container Styling */
.history-container {
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Section Styling */
.project-section,
.task-section,
.device-section,
.revision-section {
  margin: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.project-header,
.task-header,
.device-header,
.revision-header {
  background-color: #f5f5f5;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
  transition: background-color 0.3s;
}

.project-header:hover,
.task-header:hover,
.device-header:hover,
.revision-header:hover {
  background-color: #e0e0e0;
}

/* Header Text Styling */
.project-header h2,
.task-header h3,
.device-header h5,
.revision-header h6 {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.project-header h2 {
  font-size: 22px;
}

.task-header h3 {
  font-size: 20px;
}

.device-header h5 {
  font-size: 18px;
}

.revision-header h6 {
  font-size: 16px;
}

/* Content Padding */
.project-content,
.task-content,
.device-content {
  padding: 15px;
}

/* Heading Styles */
h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0 20px ;
}

/* Table Styling */
.change-table {
  width: 100%;
  border-collapse: collapse;
}

.change-table th,
.change-table td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: left;
}

.change-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.change-table td {
  background-color: #ffffff;
}

.change-item {
  background-color: #ffffff;
}

/* Toggle Icon Styling */
.toggle-icon {
  font-size: 18px;
}
</style>