<template>
  <v-card-actions class="d-flex justify-space-between sticky-footer">
    <div class="zoom-options">
      <v-btn icon @click="zoomOut">
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <span class="mx-2">{{ Math.round(zoomAdjustments.zoomLevel * 100) }}%</span>
      <v-btn icon @click="zoomIn">
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
    </div>

    <!-- Client view with review buttons -->
    <div v-if="user.is_client" class="d-flex align-center">
      <v-btn
          v-if="revisionStore.getCurrentReviewNP() > 1"
          class="btn-change-file"
          @click="goToPrevModel"
      >
        Précedent
      </v-btn>

      <div v-for="file in filesListToReview" :key="file.id">
        <span
            :style="{
              color: file.view === selectedRev.file.view ? '#e74c3c' : '#FFFFFF',
              cursor:'default',
              fontsize: '14px'
            }"
            class="mx-2"
        >
          <v-icon class="mr-2">{{ fileService().getViewIcon(file.view) }}</v-icon>
          {{ fileService().getViewText(file.view) }}
        </span>
      </div>

      <!-- Navigation and action buttons -->
      <v-btn
          v-if="filesListToReview.length > revisionStore.getCurrentReviewNP()"
          class="btn-change-file"
          @click="goToNextModel"
      >
        Suivant
      </v-btn>

      <!-- Send comments button -->
      <v-btn
          v-if="showButtonSendCommentOrCValidateRevision(enableSendCommentButton)"
          class="btn-change-file"
          @click="openCommentSubmitPermission({actionType: 'sendComments'})"
      >
        <span>Envoyer mes commentaires</span>
        <v-icon class="ml-3">mdi-send</v-icon>
      </v-btn>

      <!-- Validate mockups button -->
      <v-btn
          v-if="showButtonSendCommentOrCValidateRevision(enableValidateButton)"
          class="btn-validate-files"
          @click="openCommentSubmitPermission({actionType: 'validateMockups'})"
      >
        <span>Valider les maquettes</span>
        <v-icon class="ml-3">mdi-send</v-icon>
      </v-btn>
    </div>

    <!-- Admin view with simple navigation -->
    <div v-else class="d-flex align-center">
      <v-btn
          v-if='user.is_client'
          :disabled="currentModel === 1"
          class="btn-change-file-client-prev"
          @click="goToPrevModel"
      >
        Précedent
      </v-btn>
      <v-icon v-else
              :disabled="(user.is_client ? revisionStore.getCurrentReviewNP() : currentModel) <= 1"
              @click="goToPrevModel"
      >
        mdi-arrow-left
      </v-icon>

      <span class="mx-2">
        Maquette {{ user.is_client ? revisionStore.getCurrentReviewNP() : currentModel }} / {{ totalModels }}
      </span>

      <v-icon
          v-if="!user.is_client"
          :disabled="(user.is_client ? revisionStore.getCurrentReviewNP() : currentModel) === totalModels"
          @click="goToNextModel"
      >
        mdi-arrow-right
      </v-icon>
      <v-btn
          v-if="user.is_client"
          :disabled="(user.is_client ? revisionStore.getCurrentReviewNP() : currentModel) === totalModels"
          class="btn-change-file-client-next"
          @click="goToNextModel"
      >
        Suivant
      </v-btn>
    </div>
    <div class="d-flex align-center">
    </div>
    <DialogSubmitConfirmation
        v-if="commentSubmitPermissionPopup"
        :action-type-permission="actionTypePermission"
        :comment-submit-permission-popup="commentSubmitPermissionPopup"
        @permission-dialog-close="handleDialogPermissionClose"
        @model-large-view-close="handleModelLargeViewClose"
    />
  </v-card-actions>
</template>
<script>
import {fileService, STATUS_IN_REVISION, STATUS_TERMINATED} from "@/services/file.service.js";
import {useRevisionStore} from "@/store/useRevisionStore.js";
import {useDialogStateStore} from "@/store/DialogStateStore.js";
import DialogSubmitConfirmation from "@/components/AtomicArchitect/Molecules/DialogSubmitConfirmation.vue";
import {useDialogInfoStore} from "@/store/DialogInfoStore.js";
import {useUserStore} from "@/store/userStore.js";

export default {
  components: {DialogSubmitConfirmation},
  props: {
    zoomAdjustments: {type: Object, required: true},
    initZoomLevel: {type: Function, required: true},
  },
  data() {
    return {
      imagePined: false,
      lastZoomLevel: 100,
      changedFile: {},
      commentSubmitPermissionPopup: false,
      actionTypePermission: '',
      currentRevisionStatus : 0,
    }
  },
  computed: {
    fileList() {
      return this.dialogInfoStore.getFileList;
    },
    currentModel() {
      return this.revisionStore.getCurrentIndex();
    },
    isStatusTerminated() {
      return this.revisionStore.getSelectedRev().status.id === STATUS_TERMINATED;
    },
    status_in_revision() {
      return STATUS_IN_REVISION;
    },
    selectedRev() {
      return this.revisionStore.getSelectedRev();
    },
    enableSendCommentButton() {
      // Check if isCommentAvailable is true in any of the revisions
      return this.revisionStore.getRevisionsList().total_comments > 0;
    },
    enableValidateButton() {
      // Checks if no file has a revision with isCommentAvailable set to true
      return !this.enableSendCommentButton;
    },
    //get the list of files to view for admin
    totalModels() {
      return this.fileList.length > 0 ? this.fileList.length : 1;
    },
    //get the list of file to view for clients
    filesListToReview() {
      // Ensure required data is available
      if (!this.fileList?.length || !this.selectedRev?.status?.id) return [];

      const lastRevisionIds = this.revisionStore.getRevisionsList().lastRevisionIds;

      // Determine the relevant revision (either last or selectedRev)
      const currentRevision = lastRevisionIds.includes(this.selectedRev.id)
          ? this.selectedRev
          : {};
      if (Object.keys(currentRevision).length !== 0) {
        this.currentRevisionStatus = currentRevision.status.id;
      }
      // Filter files by matching revisions with the selected revision status
      const filesToReview = this.fileList.reduce((acc, file) => {
        const matchingRevisions = file.revisions.filter(
            (rev) => rev.status.id === this.currentRevisionStatus
        );

        if (matchingRevisions.length > 0) {
          acc.push({
            id: file.id,
            view: file.view,
            updated_at: file.updated_at,
            revisions: file.revisions,
          });
        }
        return acc;
      }, []);

      // Count files with revisions having "STATUS_IN_REVISION"
      const lastRevisionsWithStatusInRevision = filesToReview.filter((file) =>
          file.revisions.some((rev) => rev.status.id === STATUS_IN_REVISION)
      ).length;

      // Update the total formats in review in the dialog state store
      this.dialogStateStore.setTotalFormatsToReview(lastRevisionsWithStatusInRevision);
      return filesToReview; // Return filtered files
    }


  },
  setup() {
    const revisionStore = useRevisionStore()
    const dialogStateStore = useDialogStateStore()
    const dialogInfoStore = useDialogInfoStore()
    const userStore = useUserStore()
    const user = userStore.user
    return {
      revisionStore,
      dialogStateStore,
      dialogInfoStore,
      user
    };
  },
  methods: {
    fileService,
    /**
     * Zooms in the image by increasing the zoom level.
     * This function also cancels any active comment and adjusts the scroll position to keep the zoom focused on the current area.
     * If the zoom level reaches 100%, it resets the zoom level.
     */
    zoomIn() {
      this.imagePined = false;
      this.adjustZoom(0.2, 100);
    },

    /**
     * Zooms out the image by decreasing the zoom level.
     * This function adjusts the scroll position to keep the zoom focused on the current area.
     * If the zoom level reaches 100%, it resets the zoom level.
     */
    zoomOut() {
      this.imagePined = false;
      this.adjustZoom(-0.2, 100);
    },
    /**
     * Adjusts the zoom level of the image based on the provided delta.
     * Updates the image transformations and ensures the zoom level remains within the specified limits.
     * If the zoom level equals the maximum zoom percentage, it resets the zoom level.
     *
     * @param {number} deltaZoom - The amount by which to adjust the zoom level (positive to zoom in, negative to zoom out).
     * @param {number} maxZoomPercentage - The maximum zoom percentage at which the zoom level should be reset.
     */
    adjustZoom(deltaZoom, maxZoomPercentage) {
      let {zoomLevel, transformX, transformY} = this.zoomAdjustments;
      this.lastZoomLevel = zoomLevel;
      zoomLevel += deltaZoom;
      transformX = zoomLevel * 10;
      transformY += deltaZoom > 0 ? 600 : -100; // Adjust Y based on zoom direction
      const zoomPercentage = Math.round(zoomLevel * 100);
      if (zoomPercentage === maxZoomPercentage) {
        this.initZoomLevel();
      } else {
        this.zomeLevel = zoomLevel
        this.$emit('updateZoom', {zoomLevel: zoomLevel, transformX: transformX, transformY: transformY});
        this.adjustScrollPosition();
      }

    },
    /**
     * Adjusts the scroll position to center the zoom on the clicked point.
     * Uses the ratio of the new zoom level to the previous zoom level to calculate the new scroll positions.
     */
    adjustScrollPosition() {
      this.container = document.getElementById('element-to-convert');
      const zoomRatio = this.zoomLevel / this.lastZoomLevel;

      const newScrollLeft =
          this.clickX * zoomRatio - this.container.offsetWidth / 2;
      const newScrollTop =
          this.clickY * zoomRatio - this.container.offsetHeight / 2;

      this.container.scrollLeft = newScrollLeft;
      this.container.scrollTop = newScrollTop;
    },
    /**
     * Navigates to the previous model for admin.
     */
    prevModel() {
      this.initZoomLevel();
      if (this.currentModel > 1) {
        this.updateModelState(this.currentModel);
      }
    },
    /**
     * Navigates to the previous model for user.
     */
    prevModelReviewMode() {
      this.initZoomLevel();
      if (this.revisionStore.getCurrentReviewNP() > 0) {
        this.updateModelState(this.revisionStore.getCurrentReviewNP(), true);
      }
    },

    updateModelState(index, isReviewMode = false, isNext = false) {
      if (isNext) {
        index++
      } else {
        index--
      }
      if (isReviewMode) {
        this.revisionStore.setCurrentReviewNP(index);
      } else {
        this.revisionStore.setCurrentIndex(index);
      }
      this.changedFile = this.user.is_client ? this.filesListToReview[index - 1] : this.fileList[index - 1];
      if (this.changedFile) {
        if (this.user.is_client && this.changedFile.revisions[0].status.id === STATUS_IN_REVISION) {
          this.dialogStateStore.markFormatAsViewed(index - 1 );
        }
        this.$emit('newFile', this.changedFile);
      }
    },
    goToPrevModel() {
      if (this.user.is_client) {
        this.prevModelReviewMode();
      } else {
        this.prevModel();
      }
    },
    goToNextModel() {
      if (this.user.is_client) {
        this.nextModelReviewMode();
      } else {
        this.nextModel();
      }
    },

    /**
     * Navigates to the next model for admin.
     */
    nextModel() {
      this.initZoomLevel();
      if (this.currentModel < this.totalModels) {
        this.updateModelState(this.currentModel, false, true);
      }
    },

    /**
     * Navigates to the next model in review mode for client.
     */
    nextModelReviewMode() {
      this.initZoomLevel();
      this.updateModelState(this.revisionStore.getCurrentReviewNP(), true, true);
    },


    showButtonSendCommentOrCValidateRevision(type) {
      return this.filesListToReview.length === this.revisionStore.getCurrentReviewNP() &&
          type &&
          this.revisionStore.getRevisionsList().lastRevisionIds.find((revId) => revId === this.selectedRev.id) &&
          !this.isStatusTerminated &&
          this.revisionStore.getSelectedRev().status.id === STATUS_IN_REVISION
    },
    openCommentSubmitPermission(payload) {
      const {actionType} = payload;
      this.actionTypePermission = actionType;
      this.commentSubmitPermissionPopup = true;
    },
    handleDialogPermissionClose(newValue) {
      this.commentSubmitPermissionPopup = newValue;
    },
    handleModelLargeViewClose(newValue) {
      this.$emit('modelLargeViewClose', newValue);
    },
  },
}
</script>
<style lang="scss" scoped>
.sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #30322f;
  color: #d4d4d4; /* This sets the default text color */
  z-index: 1000;
}

.zoom-options {
  color: #d4d4d4;
}

.btn-change-file {
  background-color: #c90d0f !important;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.75)
  }
}


.btn-change-file-client-prev {
  border: 2px solid #FFF !important;
  margin-right: 12px;

  &:hover {
    color: rgba(255, 255, 255, 0.75)
  }
}

.btn-change-file-client-next {
  border: 2px solid #FFF !important;
  margin-left: 12px;

  &:hover {
    color: rgba(255, 255, 255, 0.75)
  }
}

.btn-validate-files {
  background-color: #0f8000 !important;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.75)
  }
}

</style>