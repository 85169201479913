import {defineStore} from "pinia";

export const useDialogStateStore = defineStore("dialogStateStore", {
  state: () => ({
    totalFormats: 1,  // Total formats to view (from DialogDetailFooterView)
    currentFormatIndex: 1,  // Start at the first format
    visualizedFormats: {},  // Track visualized formats
    canCloseDialog: false,  // Initially, the dialog cannot be closed
    fileList: []
  }),
  getters: {
    // Checks if all formats are visualized
    allFormatsViewed(state) {
     return Object.keys(state.visualizedFormats).length === state.totalFormats;
    },
    getFileList(state) {
      return state.fileList;
    }
  },
  actions: {
    setFileList(fileList){
      this.fileList = fileList;
    },
    // Set total formats, usually from DialogDetailFooterView
    setTotalFormatsToReview(total) {
      this.totalFormats = total;
    },

    // Set current format index when user navigates
    setCurrentFormatIndex(index) {
      this.currentFormatIndex = index;
    },

    // Mark a format as visualized
    markFormatAsViewed(index = 0) {
      this.visualizedFormats[index] = true;
    },
  },
});
