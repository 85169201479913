// stores/useRevisionStore.js
import {defineStore} from 'pinia';
import {fileService} from "@/services/file.service.js";

export const useRevisionStore = defineStore('revisionStore', {
  state: () => ({
    selectedRev: {},
    revisionsList: {},
    taskTitle: null,
    totalComments: 0,
    lastRevisionIds: [],
    currentIndex: 1,
    currentReviewNP: 1,
    dialogOpen: false,
    revisionLabel: ''
  }),

  getters: {
    getSelectedRevSafe: (state) => {
      return state.selectedRev || {};
    },
    getRevisionsListSafe: (state) => {
      return state.revisionsList || {};
    },

  },

  actions: {
    getRevisionsList() {
      return this.revisionsList || {};
    },

    setSelectedRev(rev) {
      try {
        this.selectedRev = rev || {};
      } catch (error) {
        console.error('Error setting selected revision:', error);
        this.selectedRev = {};
      }
    },

    getSelectedRev() {
      return this.selectedRev || {};
    },

    setCurrentIndex(index) {
      if (index !== 0) {
        this.currentIndex = index;
      }
    },

    getCurrentIndex() {
      return this.currentIndex;
    },

    setCurrentReviewNP(index) {
      this.currentReviewNP = index;
    },

    getCurrentReviewNP() {
      return this.currentReviewNP;
    },
    getRevisionLabel(){
      return this.revisionLabel
    },
    /**
     * Fetches revisions by file ID and updates the relevant data.
     * @param {Number} fileId - The ID of the file.
     * @async
     */
    async getRevisionsByFileId(fileId) {
      try {
        const revisions = await fileService().getRevisionsByFileId(fileId);
        if (!revisions?.fileRevisions || revisions.fileRevisions.length === 0) {
          console.error('No revisions found for the given fileId');
          return;
        }
        this.revisionsList = revisions;
        const {fileRevisions} = revisions;
        const [firstRevision] = fileRevisions;
        this.selectedRev = firstRevision;
        const {revisionNbr} = firstRevision;
        this.setRevisionLabel(revisionNbr);
      } catch (error) {
        console.error('Error fetching revisions:', error);
      }
    },

    /**
     * get the number revision that shown in the menu
     * @param number
     */
    setRevisionLabel(number) {
      this.revisionLabel = `Version #${number}`;
    },
  },
  clearAll(){
    this.selectedRev= {};
    this.revisionsList= {};
    this.taskTitle= null;
    this.totalComments= 0;
    this.lastRevisionIds= [];
    this.currentIndex= 1;
    this.currentReviewNP= 1;
    this.dialogOpen= false;
    this.revisionLabel= '';
  }
});
