import {defineStore} from 'pinia';

export const useTaskStore = defineStore('taskStore', {
  state: () => ({
    selectedTask: {}, // Ensure it's initialized as an object
    taskCommentsStatus: {}, // Store task-specific statuses
    title: '', // Title for additional functionality
    projectTitle: '',
    tasks: []
  }),
  getters: {
    commentsExistForSelectedTask(state) {
      return state.selectedTask ? state.taskCommentsStatus[state.selectedTask.id] || false : false; // This returns the full status object for checking
    },
  },
  actions: {
    // Set the selected task and store it in localStorage
    setSelectedTask(task) {
      this.selectedTask = task;
      localStorage.setItem('selectedTask', JSON.stringify(task));
    },

    // Get the selected task from localStorage
    getSelectedTask() {
      const task = localStorage.getItem('selectedTask');
      this.selectedTask = task ? JSON.parse(task) : {};
      return this.selectedTask;
    },

    // Mark comments as existing for a specific task
    displayAlertGraphicDesignerButton(taskId) {
      if (!this.taskCommentsStatus[taskId]) {
        this.taskCommentsStatus[taskId] = true; // Add the taskId with status true
        localStorage.setItem('taskCommentsStatus', JSON.stringify(this.taskCommentsStatus));
      }
    },

    // Remove comments and clean up localStorage
    removeComment(taskId) {
      if (taskId && this.taskCommentsStatus[taskId]) {
        delete this.taskCommentsStatus[taskId]; // Remove the taskId entry
        localStorage.setItem('taskCommentsStatus', JSON.stringify(this.taskCommentsStatus));
      }
    },

    // Set the title and store it in localStorage
    setTitle($title) {
      this.title = $title;
    },

    // Get the title from localStorage
    getTitle() {
      return this.title;
    },

    setProjectTitle($projectTitle) {
      this.projectTitle = $projectTitle;
    },

    getProjectTitle() {
      return this.projectTitle;
    },

    setTasks($tasks) {
      this.tasks = $tasks;
    },
    getTasks() {
      return this.tasks;
    }
  }
});