<template>
  <v-navigation-drawer
      :style="{
              
                  'z-index': '1004',
                  transform: 'translateX(0%)',
                  position: 'fixed',
                  height: 'calc(100% - 64px)',
                  top: '64px',
                  bottom: '0px',
                }"
      class="comment-popup"
      location="right"
      permanent
  >
    <template v-slot:prepend>
      <v-form class="comment-form" @submit.prevent="saveComment">
        <v-text-field
            id="comment"
            v-model="commentForm.content"
            :disabled="  (this.user.is_client && this.getSelectedRevStatusId != status_in_revision) ||  this.isStatusTerminated()"
            color="#05a9f4"
            placeholder="Saisir un commentaire"
            textarea
            variant="outlined"
        >
          <template v-slot:append-inner>
            <v-icon
                :color="imagePined ? '#05A9F4' : ''"
                @click="applyFilter"
            >mdi-pin-outline
            </v-icon
            >
          </template>
        </v-text-field>

        <div class="d-flex justify-end"  v-if="
        commentForm.content !== undefined && commentForm.content.trim().length > 0 && imagePined && isPinValid"
        >
          <v-btn
              :disabled="isCommentFormDisabled"
              class="me-2 cancel-btn"
              variant="flat"
              @click="cancelComment"
          >
            Annuler
          </v-btn>
          <v-btn
              :class="[
                getButtonClass(commentForm.content, true),
                { 'valid-button': validateAddComment(commentForm.content) }
              ]"
              :disabled="!validateAddComment(commentForm.content)"
              type="submit"
              variant="text"
              @click="reloadData(this.selectedRev.file)"
          >
            Valider
          </v-btn>
        </div>
      </v-form>
    </template>

    <!--list of  start-->

    <v-virtual-scroll
        v-if="comments.length> 0"
        :items="comments"
        item-height="100"
        nav
        class="comments-container"
    >
      <template v-slot:default="{ item: comment, index }" >
        <div class="comment-thread" >
          <!-- Main comment -->
          <div class="comment-item" :id='`comment-${comment.id}`'>
            <!-- Avatar with badge -->
            <div class="avatar-section" v-if="comment.parent == null">
              <v-badge 
                :color="comment.status.color"
                @click="showPin(comment.id, comment.status.color)"
                bordered 
                overlap
              >
                <template v-slot:badge>
                  <div class="badge-count">
                    {{ user.is_client ? comment.commentNbrClient : comment.commentNbrAdmin }}
                  </div>
                </template>
                <v-avatar :image="getAvatar(comment.user.avatar)" size="40" />
              </v-badge>
            </div>

            <!-- Comment content -->
            <div class="content-wrapper">
              <!-- Menu button -->
              <div class="menu-wrapper" >
                <v-menu v-if=" showCommentSettings(comment) ">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      color="grey-lighten-1"
                      icon="mdi-dots-vertical"
                      class="menu-btn"
                      v-bind="props"
                      variant="text"
                    ></v-btn>
                  </template>
                  <v-list class="comment-settings">
                    <v-list-item v-for="(commentStatus, i) in getCommentSettings(comment)" :key="i">
                      <v-list-item-title @click="handleSettingActions(commentStatus, comment)">
                        <v-icon v-if="commentStatus.title === settingActions.edit" class="mr-2 settings-icon">
                          mdi-pencil
                        </v-icon>
                        <v-icon v-if="commentStatus.title === settingActions.treat" class="mr-2 settings-icon">
                          mdi-check-network-outline
                        </v-icon>
                        <v-icon v-if="commentStatus.title === settingActions.refuse" class="mr-2 settings-icon">
                          mdi-close-network-outline
                        </v-icon>
                        <v-icon v-if="commentStatus.title === settingActions.taggedSpec" class="mr-2 settings-icon">
                          mdi-wrench-outline
                        </v-icon>
                        {{ commentStatus.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div v-if="comment.isTaggedSpec" :class=" showCommentSettings(comment) ? 'mdi-wrench-custom' : 'mdi-wrench-custom-no-menu' ">
                  <v-icon  color="rgb(201, 13, 15)">mdi-wrench</v-icon>
                </div>

              </div>

              <div class="comment-bubble" @click="showPin(comment.id, comment.status.color)">
                <div class="comment-header">
                  <span class="author">{{ comment.user.username }}</span>
                  <div class="timestamp-container">
                    <span class="date">{{  $filters.formatDateTimeSeparate(comment.created_at).date }}</span>
                    <span class="time">{{  $filters.formatDateTimeSeparate(comment.created_at).time }}</span>
                  </div>
                </div>
                
                <div v-if="!comment.isEditing" class="comment-text">
                  {{ comment.content }}
                </div>
                <v-form v-else @submit.prevent="saveEditComment(comment)">
                  <v-text-field
                    v-model="comment.content"
                    :disabled="isStatusTerminated()"
                    color="#05a9f4"
                    placeholder="Modifier le commentaire"
                    variant="outlined"
                    textarea
                  ></v-text-field>
                  <div class="pa-4 d-flex justify-end">
                    <v-btn
                      class="me-2 cancel-btn"
                      variant="flat"
                      @click="comment.isEditing = false"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      :class="!comment.content || comment.content.trim() === '' ? 'vdt-btn' : 'vdt-enabled-btn'"
                      :disabled="!comment.content || comment.content.trim() === ''"
                      type="submit"
                      variant="text"
                    >
                      Valider
                    </v-btn>
                  </div>
                </v-form>
              </div>

              <!-- Comment actions -->
              <div class="comment-actions">
                <button class="action-btn" @click="toggleCommentReplies(index, comment)">
                  <span v-if="comment.comments.length">
                    {{ comment.comments.length }} 
                    {{ comment.comments.length === 1 ? 'réponse' : 'réponses' }}
                  </span>
                  <span v-else>Répondre</span>
                </button>
                
        
              </div>
            </div>
          </div>

          <!-- Replies section -->
          <div v-show="showReplyComments[index]" class="replies-section">
            <!-- Existing replies -->
            <div v-for="reply in comment.comments" :key="reply.id" class="reply-item">
              <div class="avatar-section">
                <v-avatar :image="getAvatar(reply.user.avatar)" size="32" />
              </div>
              
              <div class="content-wrapper">
                <!-- Menu button for reply -->
                <div class="menu-wrapper" v-if="!isStatusTerminated() && reply.user.id === user.id">
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        color="grey-lighten-1"
                        icon="mdi-dots-vertical"
                        class="menu-btn"
                        v-bind="props"
                        variant="text"
                      ></v-btn>
                    </template>
                    <v-list class="comment-settings">
                      <v-list-item>
                        <v-list-item-title @click="reply.isEditing = true">
                          <v-icon class="mr-2 settings-icon">mdi-pencil</v-icon>
                          Modifier
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <div class="comment-bubble">
                  <div class="comment-header">
                    <span class="author">{{ reply.user.username }}</span>
                    <div class="timestamp-container">
                      <span class="date">{{ $filters.formatDateTimeSeparate(reply.created_at).date }}</span>
                      <span class="time">{{ $filters.formatDateTimeSeparate(reply.created_at).time }}</span>
                    </div>
                  </div>
                  
                  <!-- Reply content or edit form -->
                  <div v-if="!reply.isEditing" class="comment-text">
                    {{ reply.content }}
                  </div>
                  <v-form v-else @submit.prevent="saveEditComment(reply)">
                    <v-text-field
                      v-model="reply.content"
                      :disabled="isStatusTerminated()"
                      color="#05a9f4"
                      placeholder="Modifier la réponse"
                      variant="outlined"
                      textarea
                    ></v-text-field>
                    <div class="pa-4 d-flex justify-end">
                      <v-btn
                        class="me-2 cancel-btn"
                        variant="flat"
                        @click="reply.isEditing = false"
                      >
                        Annuler
                      </v-btn>
                      <v-btn
                        :class="!reply.content || reply.content.trim() === '' ? 'vdt-btn' : 'vdt-enabled-btn'"
                        :disabled="!reply.content || reply.content.trim() === ''"
                        type="submit"
                        variant="text"
                      >
                        Valider
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>

            <!-- Add Reply Form -->
            <v-form 
              :disabled="isStatusTerminated()" 
              class="reply-form" 
              @submit.prevent="saveComment($event, comment.id, index)"
            >
              <v-text-field 
                v-model="commentReplayForm[index]" 
                append-inner-icon="mdi-at"
                class="comment-reply-input" 
                color="#05a9f4" 
                multi-line
                placeholder="Rédiger une réponse" 
                variant="outlined"
              ></v-text-field>
              <div v-if="showAlert" class="alert alert-danger mt-2">
                Le champ de commentaire est obligatoire.
              </div>
              <div class="pa-4 d-flex justify-end" v-if="commentReplayForm[index] && commentReplayForm[index].trim() !== ''">
                <v-btn
                  class="me-2 cancel-btn"
                  variant="flat"
                  @click="cancelComment"
                >
                  Annuler
                </v-btn>
                <v-btn
                  :class="!commentReplayForm[index] || commentReplayForm[index].trim() === '' ? 'vdt-btn' : 'vdt-enabled-btn'"
                  :disabled="!commentReplayForm[index] || commentReplayForm[index].trim() === ''"
                  type="submit"
                  variant="text"
                >
                  Valider
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </template>
    </v-virtual-scroll>

    <!--buttons on the botttom  start-->
    <div class="bottom-buttons pa-4 d-flex justify-space-between">
      <div>
        <v-btn
            :class="{
                        'btn-untreated': activeButton !== 'UNTREATED',
                        'btn-active': activeButton === 'UNTREATED',
                      }"
            class="me-2 btn-custom"
            variant="flat"
            @click="setActiveButton('UNTREATED')"
        >
          <img
              role="presentation"
              :src="fileService().getPictogram('message-white')"
              alt="Message White"
              class="pictogram me-1"
          />{{ this.untreatedComment }}
          {{ untreatedTerm }}
        </v-btn>
        <v-btn
            :class="{
                        'btn-treated': activeButton !== 'TREATED',
                        'btn-active': activeButton === 'TREATED',
                      }"
            class="me-2 btn-custom"
            type="submit"
            variant="flat"
            @click="setActiveButton('TREATED')"
        >
          <img :src="fileService().getPictogram('message-check-white')"
               alt="Message Check White"
               class="pictogram me-1"
          />{{ this.treatedComment }}
          {{ treatedTerm }}
        </v-btn>
        <v-btn
            :class="{
                        'btn-rejected': activeButton !== 'REJECTED',
                        'btn-active': activeButton === 'REJECTED',
                      }"
            class="me-2 btn-custom"
            type="submit"
            variant="flat"
            @click="setActiveButton('REJECTED')"
        >
          <img
              :src="fileService().getPictogram('message-xmark-white')"
              alt="Message Xmark White"
              class="pictogram me-1"
          />{{ this.rejectedComment }} {{ rejectedTerm }}
        </v-btn>
      </div>
      <div>
        <button
            class="me-2 btn-filter-off"
            type="submit"
            variant="flat"
            @click="setActiveButton()"
        >
          <v-icon>mdi-filter-remove-outline</v-icon>
        </button>
      </div>
    </div>
    <!--buttons on the botttom  end-->
  </v-navigation-drawer>
</template>
<script>
import {fileService, STATUS_IN_REVISION, STATUS_TERMINATED} from '@/services/file.service.js';
import {settingActions} from '@/enums/settingActions.ts';
import {commentService as commentAPI, REJECTED, TREATED, UNTREATED,} from '@/services/comment.service';
import {BE_BASE_URL} from "@/config/config.js";
import {useTaskStore} from "@/store/taskStore.js";
import {useRevisionStore} from "@/store/useRevisionStore.js";
import {useUserStore} from "@/store/userStore.js";
import Toast from "vue-toastification";

const commentService = commentAPI();
export default {
  props: {
    selectedRev: {type: Object, required: true},
    commentPin: {type: Object, required: true},
    highlightedComments: {type: Object, required: true},
    imagePined: {type: Boolean, required: true},
    initZoomLevel: {type: Function, required: true}
  },

  data() {
    return {
      settingActions: settingActions,
      comments: this.revisionStore.getSelectedRev().comments ?? [],
      commentForm: {
        content: '',
        private: Boolean,
        status: Number, // You can replace 'any' with the appropriate type
        user: Number,
        parent: Number | null,
        revision: Number,
      },
      commentReplayForm: {},
      showReplyComments: [],
      activeButton: '',
      untreatedComment: this.revisionStore.getSelectedRev().commentsStats.untreated_comments || 0,
      treatedComment: this.revisionStore.getSelectedRev().commentsStats.treated_comments || 0,
      rejectedComment: this.revisionStore.getSelectedRev().commentsStats.refused_comments || 0,
      highlightedPinId: null,
      showAlert: false,
    }
  },
  setup() {
    const taskStore = useTaskStore();
    const revisionStore = useRevisionStore()
    const userStore = useUserStore();
    const user = userStore.user;
    //watch: functional component
    return {
      taskStore,
      revisionStore,
      user
    };
  },
  mounted() {
    this.fetchStatusComments();
  },
  watch: {
    selectedRev(newRev) {
      if (newRev) {
        this.initZoomLevel();
        this.comments = newRev.comments;
        this.updateCommentStats(newRev.commentsStats);
      }
    },
  },
  computed: {
    getSelectedRevStatusId() {
      return this.selectedRev.status.id;
    },

    getCommentSettings() {
      return (comment) => {
        if (!this.commentStatusList) return [];

        const adminStatusList = this.commentStatusList.slice(1, 3).map((item) => ({
          ...item,
          title: item.id === TREATED
              ? this.settingActions.treat
              : item.id === REJECTED
                  ? this.settingActions.refuse
                  : item.title,
        }));
        let modifiedItems = [];
        if(!this.user.is_client){
           modifiedItems = comment.isParent ?
               [...adminStatusList , { title: this.settingActions.taggedSpec } ] :
                { title: this.settingActions.taggedSpec }
        }

        return comment.user.id === this.user.id
            ? [{ title: this.settingActions.edit }, ...modifiedItems]
            : modifiedItems;
      };
    },
    UNTREATED() {
      return UNTREATED;
    },
    TREATED() {
      return TREATED;
    },
    REJECTED() {
      return REJECTED;
    },
    untreatedTerm() {
      return this.untreatedComment <= 1 ? 'Non-traité' : 'Non-traités';
    },
    treatedTerm() {
      return this.treatedComment <= 1 ? 'Traité' : 'Traités';
    },
    rejectedTerm() {
      return this.rejectedComment <= 1 ? 'Refusé' : 'Refusés';
    },
    status_in_revision() {
      return STATUS_IN_REVISION;
    },
    isCommentFormDisabled() {
      return (!this.user.is_client && this.selectedRev.visibile) || 
             (this.user.is_client && this.getSelectedRevStatusId != this.status_in_revision) ||
             (!this.commentPin?.CoordinateX && !this.commentPin?.CoordinateY);
    },
    isPinValid() {
      return Boolean(
        this.commentPin?.CoordinateX && 
        this.commentPin?.CoordinateY && 
        this.commentPin.CoordinateX !== 0 && 
        this.commentPin.CoordinateY !== 0
      );
    },
  },


  methods: {
    updateCommentStats(stats) {
      this.untreatedComment = stats.untreated_comments || 0;
      this.treatedComment = stats.treated_comments || 0;
      this.rejectedComment = stats.refused_comments || 0;
    },
    fileService,
    isStatusTerminated() {
      return this.getSelectedRevStatusId === STATUS_TERMINATED;
    },
    async fetchStatusComments() {
      try {
        this.commentStatusList = await commentService.getStatusComments();
      } catch (error) {
        console.error('Error fetching status comments:', error);
      }
    },
    /**
     * Fetches comments by revision ID.
     * @param {Number} revId - The ID of the revision.
     * @param status
     * @async
     */
    async fetchComments(revId, status = 0) {
      try {
        const response = await commentService.getCommentsByRevisionId(
            revId,
            status
        );
        this.comments = response.comments;
        this.updateCommentStats(response.total_comments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },
    setActiveButton(buttonType = '') {
      this.activeButton = buttonType;
      let status;
      if (buttonType === 'UNTREATED') {
        status = this.UNTREATED;
      } else if (buttonType === 'TREATED') {
        status = this.TREATED;
      } else if (buttonType === 'REJECTED') {
        status = this.REJECTED;
      } else {
        this.activeButton = '';
      }
      this.fetchComments(this.selectedRev.id, status);
    },

    /**
     * Returns the full URL of the user's avatar.
     * @param {String} item - The filename of the avatar.
     * @returns {String} The full URL of the avatar.
     */
    getAvatar(item) {
      return `${BE_BASE_URL}uploads/users/avatars/${item}`;
    },
    /**
     * Toggles the application of the filter style.
     */
    applyFilter() {
      this.initZoomLevel();
      this.zoomLevel = 1.0;
      this.$emit('imagePined', !this.imagePined);
    },
    async reloadData(file) {
      try {
        this.$emit('reloadData', file);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },

    /**
     * Saves a comment with an optional parent comment.
     * @param {Number|null} parent - The ID of the parent comment or null if it is a root comment.
     * @async
     */
    async saveComment(event, parent = null, index = null) {
      // If content is a getter function
      const content =
          parent == null
              ? this.commentForm.content
              : this.commentReplayForm[index];
      if (parent == null && (!content || content.trim() === "" || !this.isPinValid || !this.commentPin?.CoordinateX || !this.commentPin?.CoordinateY)) {
        this.$toast.error('Commentaire ne doit pas être vide ou il faut mettre un pin dans la maquette')
        return;
      }
      const {CoordinateX, CoordinateY} = this.commentPin;
      const internalComment = !this.user.is_client;

      const newComment = {
        content,
        private: internalComment,
        status: 1,
        user: this.user.id,
        parent,
        revision: this.selectedRev.id,
      };
      const newPin = {CoordinateX, CoordinateY};

      try {
        await commentService.createComment(newComment, newPin);
        await this.fetchComments(this.selectedRev.id);
        await this.reloadData(this.selectedRev.file);
       if(parent == null){
         this.taskStore.displayAlertGraphicDesignerButton(
             this.taskStore.selectedTask.id
         )
       };
        this.resetCommentForm();
        this.$emit('afterCommentSave', this.comments);
      } catch (error) {
        console.error('Error saving comment:', error);
      }
    },

    /**
     * Resets the comment form to its initial state.
     */
    resetCommentForm() {
      this.commentForm = {};
      this.commentReplayForm = {};
      this.$emit('resetCommentForm', {commentPin: {CoordinateX: 0, CoordinateY: 0}, imagePined: false});
    },
    /**
     * Toggles the visibility of comment replies.
     * @param {Number} index - The index of the comment.
     */
    toggleCommentReplies(index, comment) {
      if (!this.showReplyComments[index] && comment.comments.length > 0) {
        this.showReplyComments[index] = true;
      } else if (this.showReplyComments[index]) {
        this.showReplyComments[index] = false;
      } else {
        this.showReplyComments[index] = true;
      }
    },
    /**
     * Edits the status of a comment.
     * @param {Object} comment - The comment object.
     * @param {Object} status - The new status object.
     * @async
     */
    async editStatusComment(comment, status) {

      try {
        const editedComment = {
          ...comment,
          status: status.id,
          user: comment.user.id,
          revision: this.selectedRev.id,
          created_at: comment.created_at || new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };

        let response = await commentService.editComment(
            editedComment
        );
        this.untreatedComment = response.total_comments.untreated_comments;
        this.treatedComment = response.total_comments.treated_comments;
        this.rejectedComment = response.total_comments.refused_comments;
        this.listenForStatusChanges(comment.id, status);
      } catch (error) {
        console.error('Error editing status comment:', error);
      }
    },
    /**
     * Listens for status changes and updates the comment status.
     * @param {Number} commentId - The ID of the comment.
     * @param {Object} newStatus - The new status object.
     */
    listenForStatusChanges(commentId, newStatus) {
      const comment = this.comments.find((c) => c.id === commentId);
      if (comment) {
        comment.status.color = newStatus.color;
        comment.status.id = newStatus.id;
      }
    },
    /**
     * Handles setting actions based on the status and item.
     * @param {Object} status - The status object.
     * @param {Object} item - The item to be acted upon.
     */
    handleSettingActions(status, item) {
      switch (status.title) {
        case settingActions.treat:
        case settingActions.refuse:
          this.editStatusComment(item, status);
          break;
        case settingActions.edit:
          item.isEditing = true;
          break;
        case settingActions.delete:
          // Implement delete action here
        case this.settingActions.taggedSpec:
          this.handleTaggedSpec(item);
          break;
        default:
          console.error('Unknown action:', status.title);
      }
    },
    showPin(commentId, commentColor) {
      if (this.highlightedPinId && this.highlightedPinId !== commentId) {
        const previousPinElement = document.getElementById(`pin-${this.highlightedPinId}`);
        if (previousPinElement) {
          previousPinElement.classList.remove('flash');
          previousPinElement.style.backgroundColor = commentColor;
        }
      }
      this.highlightedPinId = commentId;
      this.$nextTick(() => {
        const pinElement = document.getElementById(`pin-${commentId}`);
        if (pinElement) {
          pinElement.scrollIntoView({behavior: 'smooth', block: 'center'});
          pinElement.classList.add('flash');
          setTimeout(() => {
            if (this.highlightedPinId === commentId) {
              pinElement.classList.remove('flash');
              pinElement.style.backgroundColor = commentColor;
            }
          }, 3000);
        } else {
          console.warn('Pin element not found for ID:', commentId);
        }
      });
    },
    // Save the modified comment
    async saveEditComment(comment) {
      try {
        await commentService.editComment({...comment, revision: this.selectedRev});
        comment.isEditing = false; // Exit edit mode
      } catch (e) {
        console.error("error update comment ", e.message);
      }

    },
    cancelComment() {
      this.$emit('imagePined', false);
      this.commentForm.content = '';
      this.commentReplayForm = {};
    },
    validateComment(content) {
      if (!content || content.trim() === '') {
        this.showAlert = true;
        console.error('Commentaire ne doit pas être vide');
        return false;
      }
      return true;
    },
    validatePin() {
      return this.isPinValid;
    },
    // Base validation for content only
    validateContent(content) {
      return content && content.trim() !== '';
    },

    // Validation for add comment form (content + coordinates)
    validateAddComment(content) {
      const hasContent = this.validateContent(content);
      const hasCoordinates = this.commentPin?.CoordinateX && 
                            this.commentPin?.CoordinateY && 
                            this.commentPin.CoordinateX !== 0 && 
                            this.commentPin.CoordinateY !== 0;
      return hasContent && hasCoordinates;
    },

    // Get button classes based on form type
    getButtonClass(content, isAddForm = false) {      const isValid = isAddForm ? 
        this.validateAddComment(content) : 
        this.validateContent(content);

      return {
        'base-button': true,
        'vdt-btn': !isValid,
        'vdt-enabled-btn': isValid
      }
    },
    async handleTaggedSpec(comment) {
      try {
        // Appel de l'API pour taguer le commentaire
        const response = await commentService.taggedSpec(comment);
        // Vérifier si l'action a réussi
        if (response && response.success) {
          this.$toast.success(response.isTagged ?
              "Commentaire marqué comme spécification avec succès !" :
              'Commentaire n\'est plus marqué comme spécification avec succès !'
          );

          // Recharger les commentaires ou rafraîchir le composant après succès
          await this.fetchComments(this.selectedRev.id); // Exemple possible
        } else {
          this.$toast.error("Impossible de marquer le commentaire comme spécification.");
        }
      } catch (error) {
        console.error("Erreur lors du marquage du commentaire comme spécification :", error);
        this.$toast.error(
            "Une erreur est survenue lors de la tentative de marquage."
        );
      }
    },
    showCommentSettings(comment){
      return !this.isStatusTerminated() && (this.user.is_client ? comment.user.id === this.user.id : true) ;
    }
  }
}
</script>
<style lang="scss" scoped>

.comment-form {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  .v-text-field {
    margin-bottom: 12px;

    :deep(.v-field) {
      border-radius: 20px;
      background-color: #f0f2f5;
      
      &:hover, &:focus-within {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      }
    }

    :deep(.v-field__input) {
      padding: 12px 16px;
      min-height: 40px;
      font-size: 0.9375rem;
      color: #050505;
    }

    :deep(.v-field__append-inner) {
      padding-top: 12px;
      
      .v-icon {
        color: #65676b;
        
        &:hover {
          color: #05A9F4;
        }
      }
    }

    ::placeholder {
      color: #65676b;
      font-size: 0.9375rem;
    }
  }

  .d-flex {
    padding-top: 8px;
    border-top: 1px solid #e4e6eb;

    .v-btn {
      height: 36px;
      min-width: 80px;
      text-transform: none;
      font-weight: 500;
      letter-spacing: 0;
    }

    .cancel-btn {
      color: #65676b;
      
      &:hover {
        background-color: #f2f3f5;
      }
      
      &:disabled {
        color: #BCC0C4;
      }
    }

    .vdt-btn {
      background-color: #E4E6EB;
      color: #BCC0C4;
    }

    .vdt-enabled-btn {
      background-color: #0095F6;
      color: #fff;
      
      &:hover {
        background-color: #1877F2;
      }
      
      &:disabled {
        background-color: #E4E6EB;
        color: #BCC0C4;
      }
    }
  }
}

.badge-avatar {
  top: 15%;
  left: 0;
  position: absolute;
}

.cancel-btn {
  background-color: transparent !important;
  color: #c5c2c2;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.cancel-btn:disabled {
  background-color: transparent !important;
  color: #c5c2c2;
}

.vdt-btn {
  background: #f7f4f4 !important;
  color: #c5c2c2 !important;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  opacity: 1;
}

.vdt-enabled-btn {
  background: #3f9efe !important;
  color: #ffffff !important;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  opacity: 1;
}

.reply-list {
  padding: 15px 20px;
  font: normal normal bold 14px/19px Roboto, sans-serif;

  .reply-avatar {
    position: absolute;
    top: 0;
    left: 0;
  }

  .options-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .reply-title {
    padding-left: 20px;

    .reply-date {
      color: #707070;
      font: normal normal normal 12px/16px Roboto , sans-serif;
    }

    .reply-name {
      color: #494949;
    }
  }

  .reply-comment {
    padding: 5px 20px;
  }
}

nav {
  right: 0px;
  z-index: 1004;
  transform: translateX(0%);
  position: fixed;
  height: calc(100% - 64px);
  top: 78px !important;
  bottom: 0px;
  width: 30% !important;
}

.comment-popup {
  background: #f0f4f7 0% 0% no-repeat padding-box;
  opacity: 1;
}


.bottom-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #dfe4e9;
}

.comment {
  left: 13%;
  padding: 0;
  margin: 0 auto;
  color: #494949;
  text-align: left;
  font: normal normal normal 14px/19px Roboto , sans-serif;
  letter-spacing: 0;
  opacity: 1;
}

.comment-settings {
  min-width: 180px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #eaeaea;
  padding: 4px 0;
  margin-top: 4px;

  .v-list-item {
    min-height: 36px;
    padding: 0 12px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    .v-list-item-title {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      color: #1a1a1a;
      
      .settings-icon {
        font-size: 16px;
        margin-right: 8px;
        color: #65676b;
      }

      &:hover .settings-icon {
        color: #1a73e8;
      }
    }
  }
}

.reply-btn {
  padding-top: 20px;
  color: #05a9f4;
  text-align: left;
  font: normal normal bold 14px/19px Roboto, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.comment-reply-input {
  padding-top: 10px;
}

// Main comment container
.comment-thread {
  padding: 8px 16px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 16px;

  .comment-item {
    display: flex;
    gap: 12px;
    position: relative;
    //flex-direction: column; // Stack main comment and replies vertically
  }

  .content-wrapper {
    flex: 1;
    position: relative;
    padding-right: 32px; // Reduced from 40px
  }

  .menu-wrapper {
    position: absolute;
    top: 8px; // Added top spacing
    right: -28px; // Move menu button outside the content area
    z-index: 1;
  }

  .menu-btn {
    min-width: 28px !important; // Slightly smaller
    height: 28px !important; // Slightly smaller
    padding: 0 !important;
    opacity: 0.6;
    
    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  }

  .comment-bubble {
    width: 100%; // Ensure bubble takes full width
    margin-right: 0; // Remove any right margin
    background: #f0f2f5;
    border-radius: 18px;
    padding: 12px;
    margin-bottom: 8px;
  }

  .comment-header {
    display: flex;
    justify-content: space-between; // This will push the timestamp to the end
    align-items: flex-start; // Align items at the top
    margin-bottom: 4px;

    .author {
      font-weight: 580;
      color: #1a1a1a;
      margin-right: 8px;
    }

    .timestamp-container {
      text-align: right;
      
      .date {
        color: #65676b;
        font-size: 13px;
        display: block;
      }
      
      .time {
        color: #65676b;
        font-size: 12px;
        display: block;
        margin-top: 2px;
      }
    }
  }

  .comment-text {
    color: #333;
    line-height: 1.4;
    margin-bottom: 12px;
  }

  .comment-actions {
    margin-top: 4px;

    button {
      color: #65676b;
      font-size: 0.75rem;
      font-weight: 600;
      padding: 4px 8px;
      border-radius: 4px;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  // Replies section directly under the main comment
  .replies-section {
    margin-top: 6px;
    font-size: 12px;
    margin-left: 40px;
    padding-left: 12px;

    .reply-item {
      display: flex;
      gap: 8px;
      margin-bottom: 6px;

      .avatar-section {
        flex-shrink: 0;

        .v-avatar {
          width: 32px;
          height: 32px;
        }
      }

      .reply-content {
        flex: 1;
        background: #f0f2f5;
        border-radius: 18px;
        padding: 8px 12px;

        .reply-author {
          font-weight: 600;
          font-size: 0.875rem;
          color: #050505;
        }

        .reply-text {
          color: #050505;
          font-size: 0.875rem;
          line-height: 1.3333;
          margin: 4px 0;
        }

        .reply-time {
          font-size: 0.75rem;
          color: #65676b;
          margin-top: 2px;
          display: block;
        }
      }
    }

    .reply-form {
      margin-left: 40px;
    }
  }
}

// Action buttons
.comment-actions {
  margin-top: 4px;
  
  button {
    color: #65676b;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
    
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}

// Valid button state
.valid-button {
  background-color: #1877f2 !important;
  color: white !important;
  
  &:hover {
    background-color: #166fe5 !important;
  }
}
</style>
<style scoped>
.btn-custom {
  text-transform: capitalize !important;
  padding: 5px 10px;
  border: none;
  border-radius: 5px !important;
  opacity: 1 !important;
  color: #fff !important;
}

.btn-untreated {
  background-color: #c90d0f !important;
}

.btn-treated {
  background-color: #0f8000 !important;
}

.btn-rejected {
  background-color: #707070 !important;
}

.btn-active {
  background-color: rgba(187, 185, 185, 0.91) !important;
}

.btn-filter-off {
  padding: 5px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  opacity: 1;
  background-color: #f7f4f4;
}

.custom-disabled-btn.v-btn:disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background: #f7f4f4 0% 0% no-repeat padding-box !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 3px !important;
  color: #c5c2c2 !important;
}

.pictogram {
  display: inline-block;
  width: 14px;
  height: 14px;
  bottom: 10%;
}

.avatar-image {
  cursor: pointer;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.8) !important;
  font: normal normal bold 13px / 18px Roboto, sans-serif;
  width: 30px !important;
  height: 30px !important;
}

.highlighted {
  background-color: rgba(190, 190, 190, 0.91);
  transition: background-color 0.5s ease;
}

.comment-user, .comment {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.v-virtual-scroll {
  padding: 16px;

  .v-list-item {
    margin-bottom: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    .primary-comment {
      padding: 12px;

      .badge-avatar {
        margin-right: 12px;

        .v-avatar {
          width: 40px;
          height: 40px;
        }
      }

      .comment-content {
        background: #f0f2f5;
        border-radius: 18px;
        padding: 12px 16px;
        margin-bottom: 8px;

        .author-info {
          font-weight: 500;
          margin-bottom: 4px;
        }

        .comment-date {
          font-size: 0.85em;
          color: #65676b;
          margin-bottom: 8px;
        }
      }

      .comment-actions {
        padding: 4px 12px;

        button {
          color: #65676b;
          font-size: 0.9em;
          padding: 4px 8px;

          &:hover {
            background: #f0f2f5;
            border-radius: 4px;
          }
        }
      }
    }

    .replies-section {
      margin-left: 48px;
      padding-left: 16px;
      border-left: 2px solid #e4e6eb;

      .v-list-item {
        margin-bottom: 16px;
        box-shadow: none;
      }
    }
  }

  .comment-thread {
    padding: 0;
    position: relative;
    .comment-item {
      background: #fff;
      border-radius: 8px;
      padding: 24px 13px;
      margin-bottom: 16px;
      display: flex;
      gap: 12px;

      .avatar-section {
        position: relative;

        .v-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .badge {
          position: absolute;
          top: -4px;
          right: -4px;
          background: #1a73e8;
          color: white;
          font-size: 12px;
          min-width: 20px;
          height: 20px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #fff;
        }
      }

      .content-section {
        flex: 1;

        .comment-header {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .author {
            font-weight: 500;
            color: #1a1a1a;
            margin-right: 8px;
          }

          .timestamp {
            color: #65676b;
            font-size: 13px;
          }
        }

        .comment-text {
          color: #333;
          line-height: 1.4;
          margin-bottom: 12px;
        }

        .actions {
          display: flex;
          gap: 16px;

          button {
            color: #65676b;
            font-size: 13px;
            font-weight: 500;
            padding: 4px 8px;
            border-radius: 4px;

            &:hover {
              background: #f2f3f5;
            }
          }
        }
      }

    }
    .mdi-wrench-custom {
      margin-top: 16px;
      position: relative;
      left: 0;
    }
    .mdi-wrench-custom-no-menu {
      margin-top: 16px;
      position: relative;
      right: 100%;
    }
    .replies {
      margin-left: 52px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: -24px;
        top: 0;
        bottom: 16px;
        width: 2px;
        background: #e4e6eb;
      }
    }
  }

  .content-wrapper {
    position: relative;
    flex: 1;
    padding-right: 32px;
  }

  .menu-wrapper {
    position: absolute;
    top: 8px;
    right: -28px;
    z-index: 1;
  }

  .menu-btn {
    min-width: 28px !important;
    height: 28px !important;
    padding: 0 !important;
    right: 24%;
    font-size: 18px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  }

  .comment-bubble {
    width: 100%;
    margin-right: 0;

    .v-form {
      padding: 12px;

      .v-text-field {
        margin-bottom: 8px;

        :deep(.v-field) {
          border-radius: 20px;
          background-color: #f0f2f5;

          &:hover, &:focus-within {
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          }
        }

        :deep(.v-field__input) {
          padding: 10px 14px;
          min-height: 40px;
          font-size: 0.9375rem;
          color: #050505;
        }

        :deep(.v-field__append-inner) {
          padding-top: 12px;

          .v-icon {
            color: #65676b;
            font-size: 20px;

            &:hover {
              color: #05A9F4;
            }
          }
        }

        ::placeholder {
          color: #65676b;
          font-size: 0.875rem;
        }
      }

      .d-flex {
        padding-top: 8px;
        border-top: 1px solid #e4e6eb;

        .v-btn {
          height: 32px;
          min-width: 70px;
          text-transform: none;
          font-weight: 500;
          letter-spacing: 0;
          font-size: 0.875rem;
        }

        .cancel-btn {
          color: #65676b;

          &:hover {
            background-color: #f2f3f5;
          }

          &:disabled {
            color: #BCC0C4;
          }
        }

        .vdt-btn {
          background-color: #E4E6EB;
          color: #BCC0C4;
        }

        .vdt-enabled-btn {
          background-color: #0095F6;
          color: #fff;

          &:hover {
            background-color: #1877F2;
          }

          &:disabled {
            background-color: #E4E6EB;
            color: #BCC0C4;
          }
        }
      }
    }
  }

  .reply-form {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    margin-top: 8px;
    margin-left: 40px;

    .v-text-field {
      margin-bottom: 8px;

      :deep(.v-field) {
        border-radius: 20px;
        background-color: #f0f2f5;

        &:hover, &:focus-within {
          background-color: #fff;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }
      }

      :deep(.v-field__input) {
        padding: 10px 14px;
        min-height: 36px;
        font-size: 0.875rem;
        color: #050505;
      }

      :deep(.v-field__append-inner) {
        padding-top: 12px;

        .v-icon {
          color: #65676b;
          font-size: 20px;

          &:hover {
            color: #05A9F4;
          }
        }
      }

      ::placeholder {
        color: #65676b;
        font-size: 0.875rem;
      }
    }

    .d-flex {
      padding-top: 8px;
      border-top: 1px solid #e4e6eb;

      .v-btn {
        height: 32px;
        min-width: 70px;
        text-transform: none;
        font-weight: 500;
        letter-spacing: 0;
        font-size: 0.875rem;
      }

      .cancel-btn {
        color: #65676b;

        &:hover {
          background-color: #f2f3f5;
        }

        &:disabled {
          color: #BCC0C4;
        }
      }

      .vdt-btn {
        background-color: #E4E6EB;
        color: #BCC0C4;
      }

      .vdt-enabled-btn {
        background-color: #0095F6;
        color: #fff;

        &:hover {
          background-color: #1877F2;
        }

        &:disabled {
          background-color: #E4E6EB;
          color: #BCC0C4;
        }
      }
    }
  }


}
</style>