import {createApp, watch} from "vue";
import App from "./components/App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import PortalVue from "portal-vue";
import VueObserveVisibility from "vue3-observe-visibility";
import {createPinia} from "pinia";
import "vuetify/styles";
import {createVuetify} from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {useUserStore} from "./store/userStore";
import filters from './utils/filters';

import Emitter from 'tiny-emitter';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import translate from "@/utils/translate.js";
import {setupInactivityListener} from "@/config/inactivityManager.js";
import  Toast,  {useToast ,POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";

const vuetify = createVuetify({
  components,
  directives,
});
const app = createApp(App)
const pinia = createPinia();

app.config.globalProperties.$emitter = new Emitter();

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axios.defaults.withCredentials = false;
axios.interceptors.request.use((config) => {
  const userStore = useUserStore();
  if (!config.url.includes('/refresh')) {

    if (userStore.isAuthenticated) {
      config.headers["Authorization"] = "Bearer " + userStore.token;
    }
  }
  return config;
});

const options = {
  // You can customize the default position
  position: POSITION.TOP_RIGHT,
  timeout: 5000, // Duration in milliseconds
  closeOnClick: true, // Close the toast when clicked
  pauseOnHover: true, // Pause the timer on hover
  draggable: true, // Make the toast draggable
  draggablePercent: 0.6, // Draggable distance as a percentage
  showCloseButtonOnHover: false, // Show the close button only on hover
  hideProgressBar: false, // Show/hide progress bar
  closeButton: "button", // Add a custom close button
  icon: true, // Show/hide icon
  rtl: false, // Right-to-left support
};
app.config.globalProperties.$filters = filters;
app.config.globalProperties.translate = translate;
app.config.globalProperties.$toast = useToast();
watch(
  pinia.state,
  (state) => {
    if (state.userStore) {
      sessionStorage.setItem("token", JSON.stringify(state.userStore.token));
      sessionStorage.setItem("refresh-token", JSON.stringify(state.userStore.refresh_token));
      sessionStorage.setItem("user", JSON.stringify(state.userStore.user));
      if (state.taskStore) {
        sessionStorage.setItem("selectedTask", JSON.stringify(state.taskStore.selectedTask));
        sessionStorage.setItem("taskCommentsStatus", JSON.stringify(state.taskStore.taskCommentsStatus));

      }

    }


  },
  {deep: true}
);
app.use(router);
pinia.use(piniaPluginPersistedstate)

app.use(pinia);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.use(VueObserveVisibility);
app.use(PortalVue);
app.use(vuetify);

app.use(Toast, options);
app.mount("#App");

// Set up inactivity listener after the app mounts
setupInactivityListener();