<template>
  <v-list class="filter-options">
    <div
        v-for="(file, index) in files"
        :key="file.id"
        class="lock-task__wrapper"
    >
      <v-icon :color="'#05A9F4'" class="format-icon" size="21">
        {{ getIcon(file) }}
      </v-icon>
      <v-list-item-title>{{ translate.translateFormat(file.view) }}</v-list-item-title>
      <v-switch
          :ref="setSwitchRef"
          @change="changeRevisionState(file.revisions[0].id, index)"
          :value="[5, 6].includes(file.revisions[0].status.id)"
          inset
          class="task-switch__toggle"
          color="#CBF8D1"
          :hide-details="true"
          :true-icon="'mdi-lock-open-variant-outline'"
          :false-icon="'mdi-lock-outline'"
      >
      </v-switch>
    </div>
  </v-list>
</template>

<script>
import {fileService} from '@/services/file.service.js';
import translate from "../../utils/translate.js";

export default {
  computed: {
    translate() {
      return translate
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedTask: {
      type: Number,
      default: null,
    },
    taskName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      files: [],
      task: {},
      switchRef: [],
    };
  },
  mounted() {
    this.loadFilesByTaskId();
  },

  methods: {
    setSwitchRef(el) {
      if (el && !this.switchRef.includes(el)) {
        this.switchRef.push(el);
      }
    },
    getIcon(file) {
      if (file.view === 'desktop' || file.view === 'other') {
        return 'mdi-monitor';
      } else if (file.view === 'laptop') {
        return 'mdi-laptop';
      } else if (file.view === 'tablet') {
        return 'mdi-tablet';
      } else if (file.view === 'mobile') {
        return 'mdi-cellphone';
      }
    },
    async loadFilesByTaskId() {
      try {
        const {files} = await fileService().getFilesByTaskId(
            this.selectedTask,
            true
        );
        this.files = files;
      } catch (error) {
        console.error('Error loading files:', error);
      }
    },
    async changeRevisionState(revisionId, index) {
      const input = this.switchRef[index].$el.querySelector('input');
      const toggleBtn = document
      .querySelector('.v-table')
      .querySelector('.js-toggle-lock[aria-expanded="true"]');
      input.setAttribute('disabled', true);
      input.setAttribute('aria-disabled', true);
      const enabledItems = this.switchRef.filter((item) => {
        return (
            item.$el.querySelector('input').getAttribute('aria-disabled') ===
            'false'
        );
      });
      if (enabledItems.length === 0) {
        toggleBtn.setAttribute('disabled', true);
        toggleBtn.classList.add('v-btn--disabled');
      }
      try {
        await fileService().updateRevisionStatus(revisionId, 4);
      } catch (error) {
        console.error('Error change state:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lock-task__wrapper {
  align-items: center;
  display: flex;

  .v-list-item-title {
    flex-grow: 1;
  }
}

.filter-options {
  background-color: #fff;
  border-radius: 10px !important;
  border: 1px solid #d4d4d4;
  height: 100%;
  margin-right: 10px;
  min-width: 215px;
  overflow: visible;
  padding: 0 22px;
}

.filter-label {
  color: #494949;
  font-size: 20px;
  font: normal normal bold 14px/19px Roboto;
  opacity: 1;
  text-align: center;
}

.format-icon {
  margin-right: 5px;
}

.box {
  position: relative;
  display: inline-block;
}

.filter-options {
  &::after {
    content: '';
    position: absolute;
    top: 30px;
    right: -10px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
    background-color: transparent;
  }

  &::before {
    content: '';
    position: absolute;
    top: 30px;
    right: -11px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #d4d4d4;
    background-color: transparent;
  }
}

.v-menu > .v-overlay__content > .v-card,
.v-menu > .v-overlay__content > .v-sheet,
.v-menu > .v-overlay__content > .v-list {
  overflow: visible;
}

.mdi-lock-open-variant-outline,
.mdi-lock-outline {
  color: #c5c5c5 !important;
}
</style>
