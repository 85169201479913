import axios from "axios";
export function taskService() {

    async function changeIsActionRequired(task_id) {
        try {
            const response = await axios.post(`/task/${task_id}/changeIsActionRequired`);
            return response.data;
        } catch (error) {
            console.error("Error changing requirement:", error.message);
            throw new Error("Failed to change isActionRequired status.");
        }
    }

  return{changeIsActionRequired}

}