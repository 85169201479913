import {defineStore} from "pinia";
import {BE_BASE_URL} from "@/config/config.js";

export const useDialogInfoStore = defineStore("dialogInfoStore", {
  state: () => ({
    fileList: [],
  }),
  getters: {
    getFileList(state) {
      return state.fileList;
    },
  },
  actions: {
    setFileList(fileList){
      this.fileList = fileList;
    },
    getImageSource(item){
      return `${BE_BASE_URL}${item}`;
    }
  },
});
