<template>
    <div class="pagination-controls">
        <div class="pagination-start">
            <h3 class="pagination-text">Affichage de 1 à {{ itemsPerPage }} sur {{ totalItems }} enregistrements</h3>
        </div>
        <div class="pagination-end">
          <v-btn v-if=" currentPage >= 5 " class="pagination-button" variant="outlined" @click="goToFirstPage">
            Première page
          </v-btn>
            <v-btn class="pagination-button" variant="outlined" :disabled="currentPage === 0" @click="previousPage">
                Précédente
            </v-btn>

            <template v-if="totalPages <= calculatedPages">
                <v-btn v-for="page in calculatedPages" :key="page"
                    :class="{ 'pagination-number': true, 'selected-page-button': currentPage === page - 1 }"
                    @click="goToPage(page)">
                    {{ page }}
                </v-btn>
            </template>

            <template v-else>

                <!-- Display pages dynamically -->
                <template v-for="page in displayedPages">
                    <v-btn :class="{ 'pagination-number': true, 'selected-page-button': currentPage === page - 1 }"
                        @click="goToPage(page)">
                        {{ page }}
                    </v-btn>
                </template>
                <v-btn v-if="currentPage < totalPages - 3" class="pagination-number" @click="showMorePages"
                    disabled>...</v-btn>
                <!-- Add button for the last page -->

                <v-btn v-if="totalPages > 1 && totalPages !== displayedPages[displayedPages.length - 1]"
                    :key="totalPages"
                    :class="{ 'pagination-number': true, 'selected-page-button': currentPage === totalPages - 1 }"
                    @click="goToPage(totalPages)">
                    {{ totalPages }}
                </v-btn>
            </template>



            <v-btn class="pagination-button" variant="outlined" :disabled="currentPage === totalPages - 1" @click="nextPage">
                Suivante
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemsPerPage: Number,
        totalItems: Number,
        currentPage: Number,
        totalPages: Number,
        pages: Array
    },

    data() {
        return {
            page_nb: 5,
        };
    },
    computed: {
        displayedPages() {
            const startPage = Math.max(1, this.currentPage - 2);
            const endPage = Math.min(this.totalPages, startPage + this.page_nb - 1);
            return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
        },
        calculatedPages() {
            const totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

            if (totalPages <= 1) return 1;
            if (totalPages <= 2) return 2;
            if (totalPages <= 3) return 3;
            if (totalPages <= 4) return 4;
            return 5;
        }
    },
    methods: {
        previousPage() {
            this.$emit("previous-page");
        },
        nextPage() {
            this.$emit("next-page");
        },
        goToPage(page) {
            this.$emit("go-to-page", page);
        },
        showMorePages() {

            // Increment the number of pages to display by 5
            this.pages += 5;
        },
        showPreviousPages() {
            // Decrement the number of pages to display by 5
            this.pages += 5;
        },
        goToLastPage() {
            // Emit a signal to go to the last page
            this.$emit("go-to-last-page", this.totalPages);
        },
      goToFirstPage() {
        this.$emit("go-to-page", 1);
      }
    }
};
</script>

<style lang="scss">
.pagination-controls {
    margin-top: 2rem;
    display: flex;
}

.pagination-start {
    flex: 2;
}

.pagination-end {
    flex: 6;
    text-align: right;
}

.pagination-text {
    text-align: left;
    font: normal normal normal 13px/18px Roboto;
    letter-spacing: 0px;
    color: #30322F;
    opacity: 1;

}

.pagination-button {
    background: #ffffff 0% 0% no-repeat padding-box;

    font: normal normal normal 13px/18px Roboto;
    color: #494949;
    text-transform: capitalize !important;
}


</style>