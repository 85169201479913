<template>
  <div class="d-flex align-center header-first-block">
    <div
        class="headline header-icon-menu-item mx-4"
        @click.stop="closeDialog"
    >
      <b>Projet</b> : {{ taskStore.getProjectTitle()}}
    </div>
    <v-divider vertical></v-divider>
    <!-- view start for task -->
    <div v-if="!modelLargeView" class="mx-4">
      <v-select
          :items="taskStore.getTasks()"
          :model-value="taskStore.getTitle()"
          bg-color="#FFFFFF"
          class="header-select "

          flat
          variant="plain"
      >
        <template v-slot:item="{ item , index }" variant="outlined">
          <v-list-item
              :id="index"
              class="header-icon-menu-item"
              :class="{ 'selected-item': taskStore.getTitle() === item.raw.title }"
              @click="handleChangeTask(item)"
          >{{ item.raw.title }}
          </v-list-item>
        </template>
      </v-select>
    </div>
      <div v-else class="header-headline mx-4" @mouseover="showTooltip" @mouseleave="hideTooltip">
        <b>Tâche</b> : <span class="truncate">{{ taskStore.getTitle() }}</span>
        <div v-if="tooltipVisible" class="custom-tooltip">{{ taskStore.getTitle() }}</div>
      </div>

    <v-divider v-if="modelLargeView" vertical></v-divider>
    <div v-if="modelLargeView" class="header-headline mx-4">
      <b>{{ translate.translateFormat(this.selectedRev.file.view) }}</b>
    </div>

    <v-divider v-if="modelLargeView" vertical></v-divider>
    <div v-if="modelLargeView" class="mx-4 headline ">
      <v-select
          :model-value="revisionStore.getRevisionLabel()"
          :items="fileRevisions"
          bg-color="#FFFFFF"
          flat
          icon="mdi-update"
          variant="plain"
      >
        <template v-slot:prepend-inner>
          <v-icon class="first-block-icon">mdi-update</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item
              class="header-icon-menu-item"
              @click="getFileByRevisionId(item.raw, item.raw.revisionNbr)"
              :class="{ 'selected-item': selectedRev.id === item.raw.id }"
          >
            {{ 'Version #' }}{{ item.raw.revisionNbr }}
          </v-list-item>
        </template>
      </v-select>
    </div>

    <v-divider v-if="modelLargeView" vertical></v-divider>
    <div v-if="modelLargeView " class="mx-4 header-headline">
      <span
        class="status-badge"
        :style="{
          backgroundColor: selectedRev.status.color,
          color: '#FFFFFF',
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ selectedRev.status.title }}
      </span>
    </div>
    <v-divider v-if="modelLargeView " vertical></v-divider>
    <div v-if="modelLargeView" class="mx-4 header-headline">
      <v-icon class="inner-select-icon" icon="mdi-update"></v-icon>
      {{

        $filters.formatDateDMYHMSversion(
            this.selectedRev.updated_at,
            false
        )
      }}
    </div>
    <v-divider v-if="modelLargeView" vertical></v-divider>
    <div v-if="modelLargeView" class="mx-2">
      <v-menu v-if="!user.is_client" class="header-icon-menu">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props">
            <v-icon
                :class="{
                    'eye-donwload-Icon': this.selectedRev.visible,
                    'eye-donwload-Icon-Off': !this.selectedRev.visible,
                    'view-icon': true,
                  }"
                :icon="
                    this.selectedRev.visible
                      ? 'mdi-eye-outline'
                      : 'mdi-eye-off-outline'
                  "
            ></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, i) in itemsEye"
              :key="i"
              class="header-icon-menu-item"
          >
            <v-list-item-title
                @click="updateVisibilityByClient( this.selectedRev.id, i)"
            >
              <div class="d-flex align-center mx-2">
                <v-icon class="inner-select-icon">{{ item.icon }}</v-icon>
              </div>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div v-if="modelLargeView" class="mx-2">
      <v-menu class="header-icon-menu">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props">
            <v-icon icon="mdi-download-outline"></v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="header-icon-menu-item">
            <v-list-item-title @click="downloadPdf">
              <v-icon
                  class="inner-select-icon"
                  icon="mdi-file-pdf-box"
              ></v-icon>
              Fichier PDF
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="header-icon-menu-item">
            <v-list-item-title @click="downloadJPG">
              <v-icon
                  class="inner-select-icon"
                  icon="mdi-image-album"
              ></v-icon>
              Image JPG
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!--download end-->
  </div>
  <v-spacer></v-spacer>
  <div class="d-flex justify-space-between header-second-block">
    <v-btn
        v-if="modelLargeView"
        :class="comment.isCommentPopupOpen ? 'comment-enabled-btn' : 'comment-btn'"
        rounded="lg"
        size="x-large"
        variant="text"
        @click="openCommentDialog"
    >
      <v-icon class="comment-btn-icon"> mdi-comment-outline</v-icon>
      {{ this.selectedRev.comments.length }} commentaires
    </v-btn>
  </div>
  <div
      v-if="!user.is_client"
      class="d-flex justify-space-between header-second-block mx-2"
  >
    <v-btn
        v-if="
              modelLargeView &&
               taskStore.commentsExistForSelectedTask &&
              selectedRev.status.id === status_in_revision /*&&
              !this.isStatusTerminated()*/
            "
        class="comment-btn"
        rounded="lg"
        size="x-large"
        variant="text"
        @click="handleAddCommentGRC(selectedTaskId, add_comment_msg)"
    >
      informer graphiste
    </v-btn>
    <v-btn
        id="btnActionRequired"
        v-if="
             revisionsList.isActionRevisionRequired  && modelLargeView &&
              selectedRev.status.id === status_to_correct /*&&
              !this.isStatusTerminated()*/
            "
        class="comment-btn"
        rounded="lg"
        size="x-large"
        variant="text"
        @click="handleAddCommentGRC(selectedTaskId , lunch_fix_msg) "
    >
      Lancer la correction
    </v-btn>
  </div>
  <v-spacer></v-spacer>

  <v-icon class="header-third-block" @click="closeDialog">mdi-close</v-icon>
  <DialogSubmitConfirmation v-if="authrizeCloseDialog"
      :comment-submit-permission-popup="true"
      :action-type-permission="'NotAuthExit'"
      :authrize-close-dialog="authrizeCloseDialog"
      @permission-dialog-close="handleCloseCongirmDialog"
      @model-large-view-close="handleModelLargeViewClose"
    />
</template>
<script>
import translate from "../../../utils/translate.js";
import html2pdf from "html2pdf.js";
import {fileService as fileAPI, STATUS_IN_REVISION, STATUS_TO_CORRECT} from "@/services/file.service.js";
import {useTaskStore} from "@/store/taskStore.js";
import {useRevisionStore} from "@/store/useRevisionStore.js";
import {ADD_COMMENT_MSG, commentService, LUNCH_FIX_MSG} from "@/services/comment.service.js";
import DialogSubmitConfirmation from "@/components/AtomicArchitect/Molecules/DialogSubmitConfirmation.vue";
import {useUserStore} from "@/store/userStore.js";
import {taskService as taskAPI} from '@/services/task.service.js';
const fileService = fileAPI();
const taskService = taskAPI();

export default {
  components: {
    DialogSubmitConfirmation
  },
  props: {
    modelLargeView: {type: Boolean, required: true, default: false},
    projectId: {type: Number, required: true, default: false},
    revisionLabel:{type: String, required:true},
    comment: {type: Object, required: true},
  },
  mounted(){
    window.addEventListener("keyup", this.handleEscape);
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.handleEscape);
    this.revisionStore.clearAll;
  },
  setup() {
    const taskStore = useTaskStore();
    const revisionStore = useRevisionStore()
    const userStore = useUserStore();
    const user = userStore.user;
    return {taskStore, revisionStore,user}
  },

  data() {
    return {
      itemsEye: [
        {title: 'Visible par le client', icon: 'mdi-eye-outline'},
        {title: 'Non-visible par le client', icon: 'mdi-eye-off-outline'},
      ],
      selectedRevisionVisibility: null,
      selectedRevisionUpdatedDate: null,
      projectTitle: '',
      projectTasks: [],
      authrizeCloseDialog: false,
      tooltipVisible: false
    }
  },
  computed: {
    translate() {
      return translate
    },
    selectedTaskId() {
      return this.taskStore.getSelectedTask().id
    },
    fileRevisions() {
      return this.revisionStore.revisionsList?.fileRevisions || [];
    },

    selectedRev() {
      return this.revisionStore.getSelectedRev();
    },
    revisionsList() {
      return this.revisionStore.revisionsList || [];
    },
    lunch_fix_msg() {
      return LUNCH_FIX_MSG;
    },
    add_comment_msg() {
      return ADD_COMMENT_MSG;
    },
    status_in_revision() {
      return STATUS_IN_REVISION;
    },
    status_to_correct() {
      return STATUS_TO_CORRECT;
    }
  },
  methods: {
    handleAddCommentGRC(taskId, model_comment){
      const response = commentService().addSyncCommentGrc(taskId, model_comment)
      if(response ){
        const data = taskService.changeIsActionRequired(taskId);
        if(data){
          this.$nextTick(() => {
            const btn = document.getElementById('btnActionRequired');

            if (btn) {
              btn.style.display = "none";
            }else{
              console.error('btn not found ')
            }
          });
        }
        this.$toast.success("Un commentaire automatique a été ajouter dans GRC", {
          timeout: 2000, // Overriding default timeout
        });
      }else{
        this.$toast.error("Erreur d'ajout de commentaire", {
          timeout: 2000, // Overriding default timeout
        });
      }
    },
    handleEscape(event){
      if (event.key === "Escape") {
        this.closeDialog();
      }
    },
    commentService,
    updateDateRevision() {
      this.selectedRevisionUpdatedDate = this.selectedRev.updated_at;
    },
    handleChangeTask(item){
      this.$emit('changeTask', item)
    },
    closeDialog() {
      if (this.user?.is_client &&
          this.revisionStore.getRevisionsList()?.lastRevisionIds?.includes(this.selectedRev.id) &&
          this.selectedRev?.status?.id === this.status_in_revision &&
          this.modelLargeView) {
        this.authrizeCloseDialog = true;
      } else {
        const projectId = this.projectId;
        if (projectId) {
          this.$router.push({
            name: 'ProjectDetails',
            params: { id: projectId}
          });
        } else {
          console.error('Project ID is undefined');
          this.$router.push({ name: 'Projects' });
        }
      }
    },
    /**
     * Fetches project details from the server.
     * @async
     */

    /**
     * Updates the visibility of the revision by the client.
     * @param {Number} revId - The ID of the revision.
     * @param {Number} index - The index to determine visibility status.
     * @async
     */
    async updateVisibilityByClient(revId, index) {
      try {
        const status = index === 0;
        const updatedRevision = await fileService.updateRevisionVisibility(
            revId,
            status
        );
        this.selectedRev.visible = updatedRevision.visible;
        this.updateDateRevision(updatedRevision.updated_at)
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * Downloads the current view as a PDF file.
     */
    async downloadPdf() {
      const element = document.getElementById('image-to-download');
      const imageUrl = element.src; // Get the original image URL
      const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

      try {
        // Fetch the image and get the Blob URL
        const blobUrl = await fileService.fetchImageAsBlobUrl(imageUrl);

        // Create a new Image element using the Blob URL
        const img = new Image();
        img.src = blobUrl;

        // Wait for the image to load fully before proceeding
        img.onload = () => {
          // Create a temporary container for the image
          const tempDiv = document.createElement('div');
          tempDiv.style.width = img.width + 'px'; // Set exact width of the image
          tempDiv.style.height = img.height + 'px'; // Set exact height of the image
          tempDiv.appendChild(img);

          // Determine orientation based on image dimensions
          const orientation = img.width > img.height ? 'landscape' : 'portrait';

          // Append tempDiv to the body to ensure rendering before conversion
          document.body.appendChild(tempDiv);

          // Generate the PDF from the temporary container
          html2pdf()
          .from(tempDiv)
          .set({
            margin: 0, // Ensure no margin to avoid extra pages
            filename: filename.replace(/\.[^/.]+$/, '') + '.pdf',
            html2canvas: {
              scale: window.devicePixelRatio, // Use the device's pixel ratio to adjust for zoom
              logging: true,
              useCORS: true, // Enable CORS for cross-origin images
            },
            jsPDF: {
              unit: 'px',
              format: [img.width, img.height], // Set PDF size to the image dimensions
              orientation: orientation, // Set dynamic orientation (landscape or portrait)
            },
          })
          .save()
          .then(() => {
            // Clean up the Blob URL and temporary div after PDF creation
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(tempDiv); // Remove the temporary div after PDF creation
          });
        };

        img.onerror = () => {
          console.error('Error loading the image from Blob.');
        };

      } catch (error) {
        console.error('Error fetching the image:', error);
      }
    },

    /**
     * Downloads the current view as a JPEG image.
     */
    async downloadJPG() {
      const element = document.getElementById('image-to-download');
      const imageUrl = element.src; // Get the original image URL
      const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

      try {
        // Fetch the image and get the Blob URL
        const blobUrl = await fileService.fetchImageAsBlobUrl(imageUrl);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename.replace(/\.[^/.]+$/, '') + '.jpg'; // Save as .jpg
        document.body.appendChild(link); // Append the link to the document
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(blobUrl);

      } catch (error) {
        console.error('Error downloading the image:', error);
      }
    },
    getFileByRevisionId(revision, index) {
      this.$emit('changeRevision', {newRevision: revision, index: index})
    },
    openCommentDialog() {
      this.$emit('newIsCommentPopupOpen', !this.comment.isCommentPopupOpen);
    },
    handleCloseCongirmDialog(isClosedDialog) {
      this.authrizeCloseDialog = isClosedDialog;
    },
    handleModelLargeViewClose(newValue) {
      this.$emit('model-large-view-close', newValue);
    },
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    }
  },
  emits: ['newIsCommentPopupOpen', 'projectTitle', 'changeRevision' , 'changeTask' , 'model-large-view-close'],
}

</script>

<style lang="scss" scoped>
.header-first-block {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10000;
  overflow: visible;
  & .headline {
    text-align: left;
    font: normal normal normal 14px/19px Roboto, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    cursor: pointer;
    padding: 0px;
  }

  & .header-headline {
    text-align: left;
    font: normal normal normal 14px/19px Roboto, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    cursor: default;
  }

  & .first-block-icon {
    color: #05a9f4;
    font: normal normal normal 13px/16px Font Awesome 6 Sharp, sans-serif;
    margin-bottom: 3px;
  }

  & .btn-icon-header {
    background: #f7f4f4 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    opacity: 1;
  }
}

.header-icon-menu-item {
  cursor: pointer;
}

.header-icon-menu-item:hover {
  background-color: #bbbb;
}

.header-select > .v-input__control {
  width: auto !important;
}

.header-second-block {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;

  & .comment-btn {
    background: #f7f4f4 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    color: #30322f;
    opacity: 1;
    font: normal normal bold 14px/19px Roboto , sans-serif;
  }

  & .comment-enabled-btn {
    background: #3f9efe 0% 0% no-repeat padding-box;
    color: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    opacity: 1;
    font: normal normal bold 14px/19px Roboto, sans-serif;
  }

  & .comment-btn-icon {
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }
}

.eye-donwload-Icon {
  text-align: center;
    font: normal normal normal 16px/19px Font Awesome 6 Sharp, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.eye-donwload-Icon-Off {
  color: #c90d0f;
  text-align: center;
  font: normal normal normal 16px/19px Font Awesome 6 Sharp, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.header-third-block {
  margin-top: 10px;
}

.status-badge {
    font: normal normal normal 13px/18px Roboto, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.inner-select-icon {
  color: #05a9f4;
    font: normal normal normal 12px/16px Font Awesome 6 Sharp, sans-serif;
  margin-bottom: 3px;
  margin-right: 2px;
}
.v-list-item-title {
  display: flex;
  align-items: center;
}
.selected-item{
  background-color: #bbbb;
}
.truncate {
  display: inline-block;
  max-width: 200px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.custom-tooltip {
  position: fixed;
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  transform: translate(5px, 5px); /* Move 5px to the right */
  z-index: 10000; /* Ensure the tooltip is on top of everything */
  overflow: visible; /* Ensure the tooltip is not clipped */
}
</style>