import axios from "axios";
import {BASE_URL} from "@/config/config.js";

export const STATUS_IN_REVISION = 4;
export const STATUS_APPROVED = 5;
export const STATUS_TO_CORRECT = 6;
export const STATUS_TERMINATED = 7;

export function fileService() {

  async function getFilesByTaskId(taskId, status = false) {
    try {
      // Construct base URL
      let url = `file/` + taskId;
      let params = {};
      if (status) {
        params = {
          status: status
        }

      }
      // Make the GET request
      const response = await axios.post(url, params);

      return response.data;
    } catch (error) {
      console.error("getFilesByTaskId error:", error.message);
      throw new Error("Failed to get files by task ID");
    }
  }

  async function getRevisionsByFileId(fileId) {
    try {
      const response = await axios.get(`revisions/list/${fileId}`);
      return response.data; // Return the response data
    } catch (error) {
      console.error("getRevisionsByFileId failed:", error);
      throw new Error("Failed to get revisions by file ID");
    }
  }

  async function updateRevisionVisibility(revId, status) {
    try {
      const requestData = {
        value: status,
      };
      const response = await axios.put(`revisions/visible/${revId}/edit`, requestData);
      return response.data.fileRevisions; // Return the response data
    } catch (error) {
      console.error("updateRevisionVisibility failed:", error);
      throw new Error("Failed to update revision visibility");
    }
  }


  async function updateRevisionStatus(revId, status) {
    try {
      const requestData = {
        statusId: status,
      };
      const response = await axios.put(`revisions/${revId}/status`, requestData);
      return response.data; // Return the response data
    } catch (error) {
      console.error("updateRevisionVisibility failed:", error);
      throw new Error("Failed to update revision visibility");
    }
  }

  /**
   * Utility function to fetch an image and return a Blob URL.
   * @param {string} imageUrl - The URL of the image to fetch.
   * @returns {Promise<string>} - A promise that resolves to the Blob URL.
   */
  async function fetchImageAsBlobUrl(imageUrl) {
    try {
      const response = await fetch(imageUrl, {
        mode: 'cors', // Ensure CORS is handled if necessary
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }

      const blob = await response.blob(); // Get the Blob from the response
      return URL.createObjectURL(blob); // Convert Blob to object URL
    } catch (error) {
      console.error('Error fetching the image:', error);
      throw error; // Propagate error for handling in calling function
    }
  }

  function getViewIcon(type) {
    const icons = {
      tablet: 'mdi-tablet',
      mobile: 'mdi-cellphone',
      desktop: 'mdi-monitor',
      laptop: 'mdi-laptop',
      other: 'mdi-monitor',
    };
    return icons[type] || 'mdi-help';
  }

  /**
   * Returns the view text based on the device type.
   * @param {String} type - The type of the device.
   * @returns {String} The view text.
   */
  function getViewText(type) {
    const texts = {
      tablet: 'Tablette',
      mobile: 'Mobile',
      desktop: 'Ecran Large',
      laptop: 'Laptop',
      other: 'Autre',
    };
    return texts[type] || 'Unknown';
  }

  function getPictogram(item) {
    return `${BASE_URL}assets/images/${item}.svg`;
  }

  return {
    getFilesByTaskId,
    getRevisionsByFileId,
    updateRevisionVisibility,
    updateRevisionStatus,
    fetchImageAsBlobUrl,
    getViewIcon,
    getViewText,
    getPictogram
  };
}