<template>
  <div class="view-container">
    <div class="item-list__wrapper">
      <div

          v-for="(item, index) in fileList"
          :key="index"
          class="view-row__container"
      >
        <div v-if="item.view !== 'other'" class="view-row view">
          <div
              v-show="(user.is_client && item.revisions[0].visible) || !user.is_client"
              style="position: relative"
          >
            <div
                :style="{ backgroundColor: item.revisions[0].status.color }"
                class="view-image-text"
            >
              <v-icon class="mx-2">mdi-magnify</v-icon>
              {{ item.revisions[0].status.title }}
            </div>
            <img
                :class="'image custom-cursor image_' + item.view"
                :src="dialogInfoStore.getImageSource(item.preview)"
                :style="{
                border: '10px solid ' + item.revisions[0].status.color,
                cursor: permissionShowViews(item, index).pointer ? 'pointer' : 'default'
              }"
                @click="showModel(item, index)"
            />
            <!-- Info Section -->
            <div>
              <div>
                <v-icon class="view-icon">{{ fileService().getViewIcon(item.view) }}</v-icon>
              </div>
              <div class="view-type">
                <div class="type-text">{{ fileService().getViewText(item.view) }}</div>
                <hr class="type-divider"/>
              </div>
              <div class="view-title">{{ item.title }}</div>
              <div class="view-details">
                <div>
                  <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                  <span class="view-details-text">Version #{{ item.revisions_nb }}</span>
                </div>
                <div>
                  <v-icon class="view-details-icon">mdi-calendar-range</v-icon>
                  {{ $filters.formatDateDMY(item.revisions[0].updated_at) }}
                </div>
                <div>
                  <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                  {{ $filters.formatTimeHMS(item.revisions[0].updated_at) }}
                </div>
              </div>
              <div class="view-comment-red">
                <img :src="fileService().getPictogram('message-white')"
                     class="pictogram me-1"
                     alt=""
                />
                {{ item.comment_statistics.untreated_comments }}
                {{ this.untreatedTerm(item.comment_statistics.untreated_comments) }}
              </div>
              <div class="view-comment-green">
                <img :src="fileService().getPictogram('message-check-white')"
                     class="pictogram me-1"
                     alt=""
                />
                {{ item.comment_statistics.treated_comments }}
                {{ this.treatedTerm(item.comment_statistics.treated_comments) }}
              </div>
              <div class="view-comment-grey">
                <img :src="fileService().getPictogram('message-xmark-white')"
                     class="pictogram me-1"
                     alt=""
                />
                {{ item.comment_statistics.refused_comments }}
                {{ this.rejectedTerm(item.comment_statistics.refused_comments) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="other-list__wrapper">
3      <div v-if=" (otherItem = this.fileList.filter(
                  (item) => item.view == 'other'
                )[0])">
        <hr style="border-color: #fff; margin: 20px 0; width: 100%"/>
        <div class="view" style="display: inline-block">
          <div
              v-show="(user.is_client && otherItem.revisions[0].visible) || !user.is_client"
              style="position: relative"
          >
            <div :style="{ backgroundColor: otherItem.revisions[0].status.color }" class="view-image-text">
              <v-icon class="mx-2">mdi-magnify</v-icon>
              {{ otherItem.revisions[0].status.title }}
            </div>
            <img
                :src="dialogInfoStore.getImageSource(otherItem.preview)"
                :style="{ border: '10px solid ' + otherItem.revisions[0].status.color }"
                class="image image_desktop custom-cursor"
                @click="showModel(otherItem, 0)"
            />
            <!-- Info Section -->
            <div>
              <div>
                <v-icon class="view-icon">{{ fileService().getViewIcon(otherItem.view) }}</v-icon>
              </div>
              <div class="view-type">
                <div class="type-text">{{ fileService().getViewText(otherItem.view) }}</div>
                <hr class="type-divider"/>
              </div>
              <div class="view-title">{{ otherItem.title }}</div>
              <div class="view-details">
                <div>
                  <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                  <span class="view-details-text">Version #{{ otherItem.revisions_nb }}</span>
                </div>
                <div>
                  <v-icon class="view-details-icon">mdi-calendar-range</v-icon>
                  {{ $filters.formatDateDMY(otherItem.revisions[0].updated_at) }}
                </div>
                <div>
                  <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                  {{ $filters.formatTimeHMS(otherItem.revisions[0].updated_at) }}
                </div>
              </div>
              <div class="view-comment-red">
                <img :src="fileService().getPictogram('message-white')" class="pictogram me-1"
                alt=""/>
                {{ otherItem.comment_statistics.untreated_comments }}
                {{ this.untreatedTerm(otherItem.comment_statistics.untreated_comments) }}
              </div>
              <div class="view-comment-green">
                <img :src="fileService().getPictogram('message-check-white')"
                     class="pictogram me-1"
                     alt=""
                />
                {{ otherItem.comment_statistics.treated_comments }}
                {{ this.treatedTerm(otherItem.comment_statistics.treated_comments) }}
              </div>
              <div class="view-comment-grey">
                <img :src="fileService().getPictogram('message-xmark-white')"
                     class="pictogram me-1"
                     alt=""
                />
                {{ otherItem.comment_statistics.refused_comments }}
                {{ this.rejectedTerm(otherItem.comment_statistics.treated_comments) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fileService, STATUS_IN_REVISION} from "@/services/file.service.js";
import {useRevisionStore} from '@/store/useRevisionStore.js';
import {useDialogInfoStore} from "@/store/DialogInfoStore.js";
import {useDialogStateStore} from "@/store/DialogStateStore.js";
import {useUserStore} from "@/store/userStore.js";

export default {
  props: {
    titleLargeModal: {type: Function, required: true},
  },
  setup() {
    const revisionStore = useRevisionStore();
    const dialogInfoStore = useDialogInfoStore();
    const dialogStateStore = useDialogStateStore();
    const userStore = useUserStore();
    const user = userStore.user;
    return {
      revisionStore,
      dialogInfoStore,
      dialogStateStore,
      user
    };
  },

  computed: {

    fileList(){
      return this.dialogInfoStore.getFileList;
    }
  },
  methods: {
    fileService,
    /**
     * Shows the model view based on the file ID and show flag.
     * @param {Number} file - The ID of the file.
     * @param {Boolean} index - Whether to show the model view.
     */
    showModel(file, index) {
  
      if (!this.permissionShowViews(file, index).permission) {
        return;
      }

      this.showRevisionsBySelectedViewType(file.id, true, index);
    },

    permissionShowViews(file, index) {
      const isClient = this.user.is_client;
      const status = file.revisions[0].status.id;

      if (isClient) {
        // Si tous les fichiers sont en révision (status.id === 4), seul le premier est accessible
        if (this.fileList.every(f => f.revisions[0].status.id === STATUS_IN_REVISION)) {
          return {
            permission: index === 0,
            pointer: index === 0
          };
        }

        // Si un fichier est en révision, permettre d'accéder uniquement au fichier suivant
        if (status === STATUS_IN_REVISION) {
          const prevFile = this.fileList[index - 1];
          // Vérification si le fichier précédent est également en révision
          if (!prevFile || prevFile.revisions[0].status.id !== STATUS_IN_REVISION) {
            return { permission: true, pointer: true }; // Accessible
          }
        }else{
          return { permission: true, pointer: true };
        }

        // Si aucune des conditions ne correspond, restreindre l'accès
        return { permission: false, pointer: false };
      }

      // Si l'utilisateur n'est pas un client, autoriser tout par défaut
      return { permission: true, pointer: true };
    },
    /**
     * Displays revisions based on the selected view type.
     * @param {Number} fileId - The ID of the file.
     * @param {Boolean} show - Whether to show the revisions.
     * @param clickedIndex
     * @async
     */
    async showRevisionsBySelectedViewType(fileId, show, clickedIndex) {
      try {
        if (show) {
          let currentIndex = 0;
          const selectedFile = this.fileList.find(file => file.id === fileId);
          const selectedStatus = selectedFile.revisions[0].status.id;

          if (this.fileList.every(item => item.revisions[0].status.id === selectedStatus)) {
            currentIndex = clickedIndex;
          } else {
            if (this.user.is_client) {
              currentIndex = this.fileList.filter(file => file.revisions[0].status.id === selectedStatus).findIndex(file => file.id === fileId);
              this.revisionStore.setCurrentReviewNP(currentIndex +1 );
              if (selectedFile.revisions[0].status.id === STATUS_IN_REVISION) {
                this.dialogStateStore.markFormatAsViewed();
              }
            } else {
              const selectedFile = this.fileList.find(file => file.id === fileId);
              currentIndex = selectedFile.view === "other" ? this.fileList.length - 1 : clickedIndex;
            }
          }
          const displayIndex = currentIndex + 1;
          this.revisionStore.setCurrentIndex(displayIndex);
          this.$emit('showModelByFileId', { fileId, displayIndex });
        }
      } catch (error) {
        console.error(error);
      }
    },
    untreatedTerm(item) {
      return item <= 1 ? 'Non-traité' : 'Non-traités';
    },
    treatedTerm(item) {
      return item <= 1 ? 'Traité' : 'Traités';
    },
    rejectedTerm(item) {
      return item <= 1 ? 'Refusé' : 'Refusés';
    },
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@mixin btn-style($background, $border) {
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: $background;
  width: fit-content;
  border: 1px solid $border;
  border-radius: 3px;
  font: normal normal normal 13px/18px Roboto;
}

.v-dialog--fullscreen {
  height: 100vh;
}

.view-image-text {
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  padding: 5px;
  text-transform: capitalize;
  border-radius: 0 0 10px 0;
}

.view-image-text {
  width: 108px;
  font-size: 13px;
  font-weight: 800;
}

.view-image-text i {
  margin: 0 !important;
}

.view-container {
  flex-direction: column;
  display: flex;
  padding: 120px 50px;
  justify-content: center;

  .view {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;

    .view_other {
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: end;
    }

    & .image {
      object-fit: cover;
      width: 100%;
      box-shadow: 0px 0px 16px #00000029;
      border-radius: 20px;
      opacity: 1;
      margin-bottom: 10px;
    }

    & .image-client {
      object-fit: cover;
      width: 100%;
      box-shadow: 0px 0px 16px #00000029;
      border-radius: 20px;
      opacity: 1;
      margin-bottom: 10px;
      border: 10px solid #e5776c;
    }

    & .image_desktop {
      height: 323px;
      width: 575px;
      object-position: 100% 0% !important;
    }

    & .image_mobile {
      width: 120px;
      height: 176px;
      object-position: 100% 0% !important;
    }

    & .image_laptop {
      width: 456px;
      height: 257px;
      object-position: 100% 0% !important;
    }

    & .image_tablet {
      width: 167px;
      height: 219px;
      object-position: 100% 0% !important;
    }

    & .view-icon,
    .view-details-icon {
      color: #05a9f4;
      font: normal normal bold 18px/24px Roboto;
    }

    & .view-icon {
      font-size: 35px;
    }

    & .view-details {
      font: normal normal normal 13px/18px Roboto;
      letter-spacing: 0px;
      color: #d4d4d4;
      opacity: 1;
      margin-bottom: 5px;

      & .view-details-text {
        color: #d4d4d4;
      }
    }

    & .view-type {
      margin-top: 5px;
      display: flex;
      align-items: center;

      & .type-text {
        flex: 2;
        margin-right: 2px;
      }

      & .type-divider {
        flex-grow: 4;
        border: none;
        border-top: 1px solid #707070;
        margin: 0;
      }
    }

    & .view-title {
      color: #707070;
      text-align: left;
      font: normal normal normal 13px/18px Roboto;
      letter-spacing: 0px;
      opacity: 1;
      margin-bottom: 5px;
    }

    & .view-comment-red {
      @include btn-style(#c90d0f, #c90d0f);
    }

    & .view-comment-green {
      @include btn-style(#0f8000, #0f8000);
    }

    & .view-comment-grey {
      @include btn-style(#494949, #494949);
    }
  }

  .item-list__wrapper {
    display: flex;
    justify-content: center;
    grid-column-gap: 3%;
  }
}

.select-wrapper {
  position: relative;
  margin-left: 5px;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    outline: white;

    &::-ms-expand {
      display: none;
    }
  }

  &.open {
    select {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><polygon points="0,0 5,5 10,0" fill="%23fff" class="arrow"/></svg>');
    }
  }

  select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><polygon points="0,5 5,0 10,5" fill="%23fff" class="arrow"/></svg>');
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  /* Define specific icon for each option */
  option[data-icon='mdi-cellphone']:before {
    background-position: 0px 0px;
    /* Position of the Mobile icon in the sprite */
  }

  option[data-icon='mdi-tablet']:before {
    background-position: -20px 0px;
    /* Position of the Tablet icon in the sprite */
  }

  option[data-icon='mdi-monitor']:before {
    background-position: -40px 0px;
    /* Position of the Desktop icon in the sprite */
  }
}

.my-custom-dialog {
  align-self: flex-end;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  margin: 80px 10px 20px 5px;
  /* Top, Right, Bottom, Left */
  scrollbar-width: none; /* Hide scrollbar if needed */
  overflow-x: auto; /* Add horizontal scrolling */
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.image-container::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  height: 0;
  /* Remove scrollbar space */
}
</style>
<style scoped>

/* Media Query for mobile and tablet */
@media (max-width: 1200px) {
  .view-container {
    flex-direction: column;
    padding: 100px 50px !important;
  }

  .view {
    margin-bottom: 20px;
  }

  .view .image_desktop,
  .view .image_mobile,
  .view .image_laptop,
  .view .image_tablet {
    width: 100%;
    height: auto;
    object-position: 100% 0% !important;
  }

  .view .image_desktop {
    width: 100% !important;
  }

  .view-image-text {
    width: 100px !important;
    font-size: 11px !important;
  }

  .view-image-text i {
    margin: 0 !important;
  }
}

.pictogram {
  display: inline-block;
  width: 14px;
  height: 14px;
  bottom: 10%;
}

.custom-cursor:hover {
  cursor: pointer;
}

.view-row__container {
  display: flex;
}

.other-list__wrapper {
  margin-top: 50px;
}
</style>