import moment from 'moment';

export default {
  timeAgo(date) {
    return moment(date).fromNow();
  },
  getMonth(date) {
    return moment(date).format("MMMM");
  },
  getDay(date) {
    return moment(date).format("DD");
  },
  formatDate(dateString) {
    const currentDate = new Date();
    const providedDate = new Date(dateString);
    const differenceInTime = currentDate.getTime() - providedDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    let msg = "";
    if (differenceInDays > 0) {
      let day = differenceInDays === 1 ? "jour" : "jours";
      msg = "Il y a " + differenceInDays + " " + day;
    } else {
      msg = "Aujourd'hui";
    }
    return msg;
  },
  formatTimeHMS(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    const parts = formattedDate.split(' ');
    const time = parts[1];
    return time;
  },
  formatDateDMY(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate;
  },

  formatDateDMYHMSversion(dateString, arobase) {
    const date = new Date(dateString);

    // Helper function to add leading zero if necessary
    const padZero = (number) => number.toString().padStart(2, '0');

    let formattedDate = `${padZero(date.getDate())}/${padZero(date.getMonth() + 1)}/${date.getFullYear()}`;

    if (!arobase) {
      formattedDate += ` - ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    } else {
      formattedDate += ` @ ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    }

    return formattedDate;
  },
  formatDateDMYHMS(dateString, arobase) {
    const date = new Date(dateString);
    let formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    if (!arobase) {
      formattedDate += ` -  ${date.getHours()}:${date.getMinutes()}`;
    } else {
      formattedDate += `  ${date.getHours()}H${date.getMinutes()}`;
    }
    return formattedDate;
  },
  formatDateTimeSeparate(dateString, arobase) {
    const date = new Date(dateString);
    // Helper function to add leading zero
    const padZero = (number) => number.toString().padStart(2, '0');

    const formattedDate = `${padZero(date.getDate())}/${padZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    const formattedTime = arobase
      ? `${padZero(date.getHours())}H${padZero(date.getMinutes())}`
      : `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;

    return {date: formattedDate, time: formattedTime};
  }
};