<template>
  <v-dialog
      :model-value="dialogVisible"
      @update:model-value="handleDialogClose"
      fullscreen
      persistent
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between sticky-title header">
        <DialogHeader
            :comment="{isCommentPopupOpen: isCommentPopupOpen}"
            :project-id="Number(this.project)"
            :revision-label="revisionLabel"
            :model-large-view="modelLargeView"
            @newIsCommentPopupOpen="handleOpenCommentDialog"
            @project-title="handleProjectDetails"
            @change-revision="handleChangeRevision"
            @change-task="handleChangeTask"
            @model-large-view-close="handleModelLargeViewClose"
        />

      </v-card-title>

      <v-card-text v-if="!modelLargeView"
                   style="background: #30322f"
                   untreatedTerm
      >
        <DialogList
            :title-large-modal="titleLargeModal"
            @show-model-by-file-id="handleShowModelByFileId"
        />
      </v-card-text>

      <v-card-text v-else style="background: #30322f">
        <!-- placeHolder for DialogDetailVue -->
        <DialogDetailView
            :is-comment-popup-open="isCommentPopupOpen"
            :current-index="currentIndex"
            @change-revision="handleChangeRevision"
            @model-large-view-close="handleModelLargeViewClose"
        >

        </DialogDetailView>
      </v-card-text>

      <!-- Footer section -->
    </v-card>
  </v-dialog>

  <template>
  </template>
</template>

<script>
import {fileService as fileAPI} from '../../services/file.service';
import {useTaskStore} from '../../store/taskStore';
import {useRevisionStore} from '../../store/useRevisionStore.js';
import {useDialogStateStore} from '../../store/DialogStateStore.js';
import {BE_BASE_URL} from '../../config/config';
import 'vuetify/styles';
import DialogHeader from "@/components/AtomicArchitect/Organisms/DialogHeader.vue";
import DialogList from "@/components/AtomicArchitect/Organisms/DialogList.vue";
import DialogDetailView from "@/components/AtomicArchitect/Organisms/DialogDetail.vue";
import {STATUS_IN_REVISION} from '@/services/file.service';
import {useDialogInfoStore} from "@/store/DialogInfoStore.js";
import {useUserStore} from "@/store/userStore.js";

const fileService = fileAPI();

export default {
  components: {DialogDetailView, DialogHeader, DialogList},
  props: {
    project: { type: String, required: true },
    taskId: { type: String, required: true }
  },

  mounted() {
    this.getFilesByTaskId(this.taskId);
  },

  setup() {
    const taskStore = useTaskStore();
    const revisionStore = useRevisionStore()
    const dialogInfoStore = useDialogInfoStore();
    const dialogStateStore = useDialogStateStore();
    const userStore = useUserStore();
    const user = userStore.user;
    return {
      taskStore,
      revisionStore,
      dialogInfoStore,
      dialogStateStore,
      user
    };
  },
  data() {
    return {
      modelLargeView: false,
      isCommentPopupOpen: false,
      fileList: [],
      revisionLabel: '',
      taskLabel: '',
      commentSubmitPermissionPopup: false,
      projectTitle: '',
      currentFileId: null,
      currentIndex: 1,
    };
  },

  computed: {
    dialogVisible() {
      return true;
    }
  },

  methods: {
    handleChangeTask(item){
      this.getFilesByTaskId(item)
    },
    async handleShowModelByFileId({ fileId, currentIndex }) {
      try {
        this.currentIndex = currentIndex;
        await this.revisionStore.getRevisionsByFileId(fileId);
        
        if (this.revisionStore.getRevisionsList().fileRevisions.length > 0) {
          if(this.user.is_client && this.revisionStore.getSelectedRev().status.id === STATUS_IN_REVISION){
            this.dialogStateStore.markFormatAsViewed();
          }
          this.modelLargeView = true;
          this.titleLargeModal();
        }
      } catch (error) {
        console.error("Error in handleShowModelByFileId:", error);
      }
    },

    /**
     * Returns the full URL of an image.
     * @param {String} item - The relative path of the image.
     * @returns {String} The full URL of the image.
     */
    getImageSource(item) {
      return `${BE_BASE_URL}${item}`;
    },
    /**
     * Fetches files by task ID and updates the relevant data.
     * @param {Number} taskId - The ID of the task.
     * @async
     */
    async getFilesByTaskId(taskId) {
      try {
        const {id} = typeof taskId === 'string' // if in dialogHeader i get an object task
            ? {id: taskId, title: this.taskStore.getTitle()}
            : {id: taskId.raw.id, title: taskId.raw.title};
        const {files , taskTitle , projectTitle , tasks} = await fileService.getFilesByTaskId(id);
        this.dialogInfoStore.setFileList(files);
        this.taskStore.setTitle(taskTitle);
        this.taskStore.setProjectTitle(projectTitle);
        this.taskStore.setTasks(tasks);
        this.taskStore.setSelectedTask(tasks.find(task => task.id == taskId));

      } catch (error) {
        this.fileList = [];
        console.error(`Error getting files by task ID: ${error}`);
      }
    },



    /**
     * Opens the comment dialog.
     */
    handleOpenCommentDialog(isCommentPopupNew) {
      this.isCommentPopupOpen = isCommentPopupNew;
    },

    titleLargeModal() {
      const viewText = fileService.getViewText(useRevisionStore().getSelectedRev().file.view);
      document.title = `${viewText} - ${this.taskStore.getTitle()} - ${this.projectTitle} - Espace création STRATIS`;
    },
    handleProjectDetails(projectTile) {
      this.projectTitle = projectTile;
    },

    handleChangeRevision(changedRevision) {
      const {newRevision, index} = changedRevision;
      this.revisionStore.setSelectedRev(newRevision)
      this.revisionStore.setRevisionLabel(index);
    },
    async handleModelLargeViewClose(newValue) {
      this.modelLargeView = newValue;
      this.currentFileId = null;
      await this.getFilesByTaskId(this.taskId);
    },
    handleDialogClose() {
      // Handle dialog close if needed
      this.$router.push({
        name: 'ProjectDetails',
        params: { id: this.project }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.v-card-text {
  padding: 0rem;
}

.v-dialog > .v-overlay__content > .v-card > .v-card-text {
  padding: 0px;
}

.sticky-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Adjust z-index as needed */
  background-color: #fff;
  /* Optional: Add a background color for better readability */
}
</style>